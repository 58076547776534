<script>
import { mapState } from 'vuex'
import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'

export default {
  name: 'AdminAccountsView',
  components: {
    SideBar,
    NavBar
  },
  data: () => ({
    accounts: [],
    search: '',
    searching: null
  }),
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    } else if (this.currentUser && this.isCustomer) {
      this.$router.push('/accounts')
    }

    this.$router.push('/admin/accounts')
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  }
}
</script>
