import axios from 'axios'
import { API_URL } from '@/config'

import accounts from './api/accounts'
import auth from './api/auth'
import users from './api/users'
import plans from './api/plans'
import subscriptions from './api/subscriptions'
import payments from './api/payments'
import sessions from './api/sessions'
import dashboard from './api/dashboard'
import videos from './api/videos'

const api = axios.create({ baseURL: API_URL })

api.defaults.headers.common['Authorization'] = localStorage.getItem('jwt-token')
// api.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate'
// api.defaults.headers.common['Pragma'] = 'no-cache'
// api.defaults.headers.common['Expires'] = '0'

api.interceptors.response.use(
  (response) => {
    if (response?.data?.token) {
      localStorage.setItem('jwt-token', response.data.token)
      api.defaults.headers.common['Authorization'] = response.data.token
    }

    return response
  },
  (error) => {
    if (error?.response) {
      const { url } = error.response.config
      const { status } = error.response

      if (status === 401 && url !== '/users/sign_in') {
        localStorage.removeItem('jwt-token')
        localStorage.removeItem('vuex')
        api.defaults.headers.common = {}
        window.location.href = '/sign-in'
      }
    }

    return Promise.reject(error)
  }
)

export const onSignout = async () => {
  localStorage.removeItem('jwt-token')
  localStorage.removeItem('vuex')
  api.defaults.headers.common = {}
}

export default {
  accounts: accounts(api),
  auth: auth(api),
  users: users(api),
  plans: plans(api),
  subscriptions: subscriptions(api),
  payments: payments(api),
  sessions: sessions(api),
  dashboard: dashboard(api),
  videos: videos(),
}
