import { getCookie, setCookie } from '@/utils/cookies'

const ROTATE = [
  0,
  90,
  270
]

const PAGES_WITH_ROTATE = [
  'accounts',
  'accounts-config',
  'admin'
]

export default {
  methods: {
    rotate() {
      const currentRotate = getCookie('rotate')
      const index = ROTATE.indexOf(Number(currentRotate))
      const nextRotate = ROTATE[(index + 1) % ROTATE.length]
      
      setCookie('rotate', nextRotate)

      this.refreshRotate()
    },
    refreshRotate() {
      const currentRotate = getCookie('rotate')

      if (currentRotate === null) {
        setCookie('rotate', 0)
        return this.refreshRotate()
      }

      if (currentRotate !== '0' && PAGES_WITH_ROTATE.includes(this.$route.name)) {
        document.getElementById('app').style.transform = `rotate(${currentRotate}deg)`
        document.getElementById('app').style.width = '100vh'
        document.getElementById('app').style.height = '100vw'
      } else {
        document.getElementById('app').style.transform = ''
        document.getElementById('app').style.width = '100vw'
        document.getElementById('app').style.height = '100%'
      }

      this.refreshButtons()
    },
    refreshButtons() {
      document.addEventListener('keydown', (event) => {
        if (event.detail === 1) return

        const currentRotate = getCookie('rotate')

        if (currentRotate === '90') {
          if (event.key === 'ArrowRight') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown', detail: 1 }))
          } else if (event.key === 'ArrowDown') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowLeft', detail: 1 }))
          } else if (event.key === 'ArrowLeft') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowUp', detail: 1 }))
          } else if (event.key === 'ArrowUp') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowRight', detail: 1 }))
          }
        } else if (currentRotate === '270') {
          if (event.key === 'ArrowRight') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowUp', detail: 1 }))
          } else if (event.key === 'ArrowDown') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowRight', detail: 1 }))
          } else if (event.key === 'ArrowLeft') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown', detail: 1 }))
          } else if (event.key === 'ArrowUp') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowLeft', detail: 1 }))
          }
        } else {
          if (event.key === 'ArrowRight') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowRight', detail: 1 }))
          } else if (event.key === 'ArrowDown') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown', detail: 1 }))
          } else if (event.key === 'ArrowLeft') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowLeft', detail: 1 }))
          } else if (event.key === 'ArrowUp') {
            event.preventDefault()
            document.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowUp', detail: 1 }))
          }
        }
      })
    }
  }
}
