<template>
  <div class="position-relative w-100">
    <div class="d-flex position-absolute input-date-sessions-by-day">
      <input type="date"
            class="form-control"
            v-model="sessionsByDay.date"
            @change="fetchMetrics"
      />
      <button class="btn btn-default ms-2" @click="fetchMetrics">
        <img src="@/assets/icons/refresh.png" class="icon-sm" alt="Atualizar" />
      </button>
    </div>
    <div class="col-12">
      <apexchart class="p-4"
                  type="line"
                  v-if="!loading"
                  :options="sessionsByDay.chartOptions"
                  :series="sessionsByDay.seriesData"
                  height="350"
      />
      <div v-else class="d-flex justify-content-center align-items-center w-100 h-100  min-height-graph-350">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import DateFormat from '@/mixins/DateFormat'

export default {
  name: 'SessionsByDayChart',
  data() {
    return {
      loading: true,
      sessionsByDay: {
        seriesData: [],
        chartOptions: {},
        date: new Date().toISOString().split('T')[0]
      }
    }
  },
  created() {
    this.fetchMetrics()
  },
  methods: {
    async fetchMetrics() {
      try {
        this.loading = true
        const response = await api.dashboard.sessionsByDay({
          account_id: this.$route.params.account_id,
          date: this.sessionsByDay.date
        })
        
        this.mountChart(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    mountChart(data) {
      this.sessionsByDay.date = data.date

      this.sessionsByDay.seriesData = [
        {
          name: 'Telas ativas',
          data: data.series,
          type: 'area'
        }
      ]

      const categories = data.labels.map((label) => `Hora: ${label}`);
      const overwriteCategories = data.labels.map((label, index) => index % 12 === 0 ? label : "");

      this.sessionsByDay.chartOptions = {
        chart: {
          id: 'active-sessions-chart',
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories,
          overwriteCategories,
          title: {
            text: 'Hora',
            offsetY: -30,
            style: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Telas ativas',
            offsetX: 15,
            style: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px'
            }
          },
          labels: {
            formatter: function (value) {
              return value?.toFixed(0) >= 0 ? value?.toFixed(0) : ''
            }
          }
        },
        title: {
          text: `Telas ativas no dia ${this.formatDate(data.date)}`,
          align: 'left',
          offsetX: 10,
          offsetY: 10,
          style: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '20px',
            fontWeight: 'normal'
          }
        },
        tooltip: {
          enabled: true
        },
        colors: ['#3169A3'],
        fill: {
          type: 'solid',
          colors: ['#D8EAFD']
        },
        stroke: {
          width: 3
        }
      }
    }
  },
  mixins: [
    DateFormat
  ],
  watch: {
    '$route.params.account_id': 'fetchMetrics'
  }
}
</script>

<style scoped>
.input-date-sessions-by-day {
  top: 30px;
  right: 35px;
  z-index: 1;
}

.min-height-graph-350 {
  min-height: 350px;
}
</style>