<template>
  <div class="padding-page d-flex flex-column align-self-start w-100 pb-5">
    <NavBar />

    <div class="content d-flex flex-column justify-content-center align-self-center">
      <span v-html="locale.credit_card.title" class="fs-30 fw-bolder mb-1" />
      <div class="credit-cards d-flex w-100">
        <img :src="cardIcon(card)" :alt="card" class="credit-card-flag" v-for="card in availableCards" :key="card" />
      </div>

      <form @submit.prevent="submitForm" class="mt-4">
        <label for="customer_name" class="ms-2 fs-16 fw-bold fc-label mb-1" v-text="locale.credit_card.card_number" />
        <input type="text" class="input-form fw-lighter" v-model="v$.card_number.$model" v-mask="'#### #### #### ####'" />
        <span v-if="v$.card_number.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.card_number.$errors[0]?.$message }}</span>

        <div class="row d-flex justify-content-between mt-3">
          <div class="col-6">
            <label for="expiration_date" class="ms-2 fs-16 fw-bold fc-label mb-1" v-text="locale.credit_card.expiration_date" />
            <input placeholder="MM/AAAA" type="text" class="input-form fw-lighter" v-model="v$.card_expiration_date.$model" v-mask="'##/####'" />
            <span v-if="v$.card_expiration_date.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.card_expiration_date.$errors[0]?.$message }}</span>
          </div>
          <div class="col-6">
            <label for="cvv" class="ms-2 fs-16 fw-bold fc-label mb-1" v-text="locale.credit_card.cvv" />
            <input placeholder="CVV" type="text" class="input-form fw-lighter" v-model="v$.card_cvv.$model" v-mask="'####'" />
            <span v-if="v$.card_cvv.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.card_cvv.$errors[0]?.$message }}</span>
          </div>
        </div>

        <div>
          <label for="customer_name" class="ms-2 fs-16 fw-bold fc-label mt-3 mb-1" v-text="locale.credit_card.card_holder" />
          <input type="text" class="input-form fw-lighter" v-model="v$.card_holder_name.$model" />
          <span v-if="v$.card_holder_name.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.card_holder_name.$errors[0]?.$message }}</span>
        </div>

        <label for="installments" class="ms-2 fs-16 fw-bold fc-label mt-3 mb-1" v-text="locale.credit_card.installments" />
        <select v-model="installments" class="input-form fw-lighter">
          <option v-for="i in 12" :key="i" :value="i" v-text="`${i}x ${currency(selectedPlan.total / i)} (sem juros)`" />
        </select>

        <label for="resume_plan" class="ms-2 fs-16 fw-bold fc-label mt-3 mb-1" v-text="locale.credit_card.resume_plan" />
        <div class="d-flex label-total w-100 p-3 justify-content-between">
          <div class="d-flex flex-column">
            <span v-text="currency(selectedPlan.total)" class="fw-bold" />
            <span v-text="selectedPlan.name" class="fw-lighter" />
          </div>

          <button type="button" @click="changePlan" class="btn btn-link fw-lighter label-change-plan">
            <span class="fw-bold fs-12">
              {{ locale.credit_card.change_plan }}
            </span>
          </button>
        </div>

        <div class="form-check mt-2">
          <input type="checkbox" v-model="v$.contract.$model" id="contract" class="form-check-input" />
          <label for="contract" class="form-check-label">
            <span v-html="locale.credit_card.contract" />
          </label>
          <span v-if="v$.contract.$errors.length && submitted" class="d-block color-danger fs-12">{{ v$.contract.$errors[0]?.$message }}</span>
        </div>

        <div class="form-check mt-1">
          <input type="checkbox" v-model="v$.terms.$model" id="terms" class="form-check-input" />
          <label for="terms" class="form-check-label">
            <span v-html="locale.credit_card.terms_of_service" />
          </label>
          <span v-if="v$.terms.$errors.length && submitted" class="d-block color-danger fs-12">{{ v$.terms.$errors[0]?.$message }}</span>
        </div>

        <div class="d-flex flex-column justify-space-between mt-4">
          <button type="submit" class="w-100 btn-confirm-filled d-flex align-items-center justify-content-center">
            <span class="fw-bold fs-15" v-if="!loading">
              {{ locale.credit_card.confirm }}
            </span>
            <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
              <span class="visually-hidden"></span>
            </div>
          </button>

          <button type="button" class="btn btn-link fc-label mt-3 mb-3" @click="goBack">
            <span class="fw-bold fs-15">
              {{ locale.credit_card.back }}
            </span>
          </button>
        </div>
      </form>

      <p class="mt-2 fs-12 color-paragraph" v-text="locale.credit_card.secure_message"></p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email, sameAs } from '@vuelidate/validators'
import api from '@/services/api'
import locale from '@/config/locales'
import { deleteCookie, getCookie, setCookie } from '@/utils/cookies'

import NavBar from '@/components/NavBar.vue'
import Currency from '@/mixins/Currency'

export default {
  name: 'PaymentsView',
  data() {
    return {
      submitted: false,
      loading: false,
      showForm: 0,
      plan: {},
      selectedPlan: {},
      availableCards: [
        'mastercard',
        'visa',
        'elo',
        'hipercard',
        'amex',
        'diners',
        'cabal'
      ],
      installments: 12
    }
  },
  components: {
    NavBar
  },
  async created() {
    
    await this.fetchPlan()
    this.selectedPlan = this.refreshSelectedPlan()
    
    if (!this.selectedPlan?.id || !this.currentUser) {
      this.$router.push('/plans')
      return
    }

    this.v$.customer_name.$model = this.currentUser.name
    this.v$.customer_email.$model = this.currentUser.email
    this.v$.tax_id.$model = this.currentUser.document

    // remover mock
    // this.v$.card_holder_name.$model = 'Vinicius Alfonso'
    // this.v$.card_number.$model = '4539620659922097'
    // this.v$.card_expiration_date.$model = '12/2030'
    // this.v$.card_cvv.$model = '123'
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    }
  },
  methods: {
    async submitForm() {
      if (this.loading) return

      this.loading = true

      try {
        this.submitted = true

        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const expMonth = this.v$.card_expiration_date.$model.split('/')[0]
        const expYear = this.v$.card_expiration_date.$model.split('/')[1]

        const cardParams = {
          publicKey: process.env.VUE_APP_PAGSEGURO_PUBLIC_KEY,
          holder: this.v$.card_holder_name.$model,
          number: this.v$.card_number.$model.replaceAll(' ', ''),
          expMonth,
          expYear,
          securityCode: this.v$.card_cvv.$model
        }

        // eslint-disable-next-line
        const card = PagSeguro.encryptCard(cardParams)

        const encrypted = card.encryptedCard

        const params = {
          user_id: this.currentUser.id,
          plan_id: this.selectedPlan.id,
          screens: this.selectedPlan.screens,
          customer: {
            name: this.v$.customer_name.$model,
            tax_id: this.v$.tax_id.$model.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
            email: this.v$.customer_email.$model
          },
          items: [
            {
              reference_id: this.selectedPlan.id,
              name: this.selectedPlan.name,
              quantity: 1,
              unit_amount: parseInt(this.selectedPlan.total) * 100
            }
          ],
          charges: [
            {
              description: `Cobrança do plano anual do MeuStory.TV`,
              amount: {
                value: parseInt(this.selectedPlan.total) * 100,
                currency: "BRL"
              },
              payment_method: {
                soft_descriptor: 'Plano anual MeuStory.TV',
                type: "CREDIT_CARD",
                installments: this.installments,
                capture: true,
                card: {
                  encrypted,
                  store: false
                },
                holder: {
                  name: this.v$.card_holder_name.$model,
                  tax_id: this.v$.tax_id.$model
                }
              }
            }
          ]
        }

        const response = await api.payments.create(params)

        if (response.status === 200) {
          const { status } = response.data.payments[0]

          if (status !== 'DECLINED' && status !== 'CANCELED') {
            deleteCookie('selectedPlan')
          }

          this.$router.push({
            path: '/checkout/finish',
            query: {
              status
            }
          })
        }
      } catch (error) {
        console.error('Erro ao alterar licença:', error)
        if (error.response.status === 422) {
          error.response.data.forEach((error) => {
            if (error.description == 'must be a valid CPF or CNPJ') {
              alert('CPF/CNPJ precisa ser válido')
            }
          })
        } else {
          console.error(error)
        }
      } finally {
        this.loading = false
      }
    },
    openForm(form) {
      this.showForm = form
    },
    async fetchPlan() {
      try {
        const response = await api.plans.all()
        this.plan = response.data[0]
      } catch (error) {
        console.error('Erro ao buscar planos:', error)
      }
    },
    cardIcon(card) {
      return require(`@/assets/images/cards/${card}.png`)
    },
    changePlan() {
      this.$router.push('/plans')
    },
    goBack() {
      this.$router.push('/checkout/shipping-address')
    },
    refreshSelectedPlan() {
      const plan = getCookie('selectedPlan')
      
      if (plan) {
        const selectedPlan = JSON.parse(plan)
        const price_screens = ((parseInt(selectedPlan.screens) - 1) * parseFloat(this.plan.additional_screen_price))
        selectedPlan.base_price = parseFloat(this.plan.base_price)
        selectedPlan.billing_cycle = parseInt(this.plan.billing_cycle)
        selectedPlan.total = (parseFloat(this.plan.base_price) + price_screens) * parseInt(this.plan.billing_cycle)

        setCookie('selectedPlan', JSON.stringify(selectedPlan))
        return selectedPlan
      }

      return {}
    }
  },
  setup () {
    const customer_name = ref('')
    const customer_email = ref('')
    const tax_id = ref('')
    const card_number = ref('')
    const card_holder_name = ref('')
    const card_expiration_date = ref('')
    const card_cvv = ref('')
    const terms = ref(false)
    const contract = ref(null)

    const rules = {
      customer_name: {
        required: helpers.withMessage('Informe o nome completo', required)
      },
      customer_email: {
        required: helpers.withMessage('Informe o e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      tax_id: {
        required: helpers.withMessage('Informe um CPF', required)
      },
      card_number: {
        required: helpers.withMessage('Informe o número do cartão', required)
      },
      card_holder_name: {
        required: helpers.withMessage('Informe o nome do titular', required)
      },
      card_expiration_date: {
        required: helpers.withMessage('Informe a validade do cartão', required)
      },
      card_cvv: {
        required: helpers.withMessage('Informe o CVV', required)
      },
      terms: {
        required: helpers.withMessage('Você precisa aceitar os termos de serviço e política de privacidade', required),
        sameAs: helpers.withMessage('Você precisa aceitar os termos de serviço e política de privacidade', sameAs(true))
      },
      contract: {
        required: helpers.withMessage('Você precisa aceitar o contrato', required),
        sameAs: helpers.withMessage('Você precisa aceitar o contrato', sameAs(true))
      }
    }

    const v$ = useVuelidate(rules, {
      customer_name,
      customer_email,
      tax_id,
      card_number,
      card_holder_name,
      card_expiration_date,
      card_cvv,
      terms,
      contract
    })

    return { v$ }
  },
  mixins: [
    Currency
  ],
  watch: {
    $route: {
      handler() {
        this.v$.customer_name.$model = this.currentUser.name
        this.v$.customer_email.$model = this.currentUser.email
        this.v$.tax_id.$model = this.currentUser.document
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>
.input-form::placeholder {
  font-size: 12px;
}

.label-total {
  min-height: 40px;
  border: 1px solid #dfdfdf;
  border-radius: 13px;
  color: #ABAAAA;
  background-color: #F1F1F1;
}

.label-change-plan {
  color: #04AAA4;
  text-decoration: none;
}

.checkbox-input {
  width: 20px;
  height: 20px;
}
</style>