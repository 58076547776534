<template>
  <div>
    <button class="btn btn-link" @click="toggleMenu">
      <img src="@/assets/images/profile.png" class="icon-md" alt="Perfil" />
    </button>
    <div v-if="showMenu" class="profile-drop-down d-flex flex-column padding-page">
      <button class="btn btn-link border-bottom d-flex align-items-center" @click="goToAdmin" v-if="isAdmin">
        <img src="@/assets/icons/control-panel.png" class="icon-sm me-2" :alt="locale.account.sidebar.control_panel" />
        {{ locale.account.sidebar.control_panel }}
      </button>
      <button class="btn btn-link border-bottom d-flex align-items-center" @click="goToAccount">
        <img src="@/assets/icons/account-user-outlined.png" class="icon-sm me-2" alt="Minha conta" />
        {{ locale.account.sidebar.account }}
      </button>
      <button class="btn btn-link border-bottom d-flex align-items-center" @click="goToPlan">
        <img src="@/assets/icons/account-plan-outlined.png" class="icon-sm me-2" :alt="locale.account.sidebar.plan" />
        {{ locale.account.sidebar.plan }}
      </button>
      <!-- <button class="btn btn-link border-bottom d-flex align-items-center" @click="goToTutorials">
        <img src="@/assets/icons/account-tutorials-outlined.png" class="icon-sm me-2" :alt="locale.account.sidebar.tutorials" />
        {{ locale.account.sidebar.tutorials }}
      </button> -->
      <button class="btn btn-link border-bottom d-flex align-items-center" @click="rotate">
        <img src="@/assets/images/rotate.png" class="icon-sm me-2" :alt="locale.account.sidebar.rotate" />
        {{ locale.account.sidebar.rotate }}
      </button>
      <button class="btn btn-link d-flex align-items-center" @click="logout">
        <img src="@/assets/images/logout.png" class="icon-sm me-2" :alt="locale.account.sidebar.logout" />
        {{ locale.account.sidebar.logout }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onSignout } from '@/services/api'
import Rotate from '@/mixins/Rotate'
import locale from '@/config/locales'

export default {
  name: 'ProfileButton',
  data() {
    return {
      showMenu: false
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }
    this.refreshRotate()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    },
    isAdmin() {
      return this.currentUser?.permission && this.currentUser?.permission >= 1
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    logout() {
      this.setCurrentUser({})
      onSignout()
      this.$router.push('/sign-in')
    },
    goToAccount() {
      this.$router.push('/account/user')
    },
    goToPlan() {
      this.$router.push('/account/plan')
    },
    goToTutorials() {
      this.$router.push('/account/tutorials')
    },
    goToAdmin() {
      this.$router.push('/admin/accounts')
    }
  },
  mixins: [
    Rotate
  ]
}
</script>

<style scoped>
.profile-drop-down {
  position: absolute;
  transform: translate(-120px, 0);
  background-color: white;
  border: 1px solid #9E9B9B;
  border-radius: 10px;
  padding: 10px;
  width: 210px;
  text-align: center;
}

.profile-drop-down button {
  text-decoration: none;
  color: #5A5858;
}
</style>