<template>
  <router-view />
</template>

<script>
import { useHead } from '@vueuse/head'
import Rotate from '@/mixins/Rotate'
export default {
  name: 'App',
  setup () {
    useHead({
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1'
        }
      ]
    })
  },
  mixins: [
    Rotate
  ],
  watch: {
    $route() {
      this.refreshRotate()
    }
  }
}
</script>

<style>
.rotate {
  transform: rotate(90deg);
  transform-origin: center;
  text-align: center;
  /* padding: 40px; */
}

.background-primary {
  background-color: #902584 !important;
}

.background-silver {
  background-color: #F9F9F9;
}

body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  color: #202020;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-danger {
  color: #C00101;
}

.back-link {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
  text-decoration: none;
  color: white;
}

.w-30 {
  width: 30%;
}

.full-screen {
  height: 100%;
  width: 100%;
}

.pv-17 {
  padding-top: 68px;
  padding-bottom: 68px;
}

.ph-13 {
  padding-top: 52px;
  padding-bottom: 52px;
}

a, a:hover, a:active, a:visited {
  color: #5A5858;
}

.color-primary {
  color: #701E66;
}

.border-primary {
  border: 1px solid #701E66 !important;
}

.border-5 {
  border-width: 5px !important;
}

.button {
  width: 290px;
  height: 58px;
  border-radius: 30px;
  border: none;
}

.button-primary, .button-primary:hover {
  background-color: #701E66;
  color: white;
}

.button-primary-outline {
  background-color: white;
  color: #701E66;
  border: 1px solid #701E66;
}

.btn-default {
  border: 1px solid #ced4da;
}

.margin-top-background-login {
  margin-top: 137px;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-30 {
  font-size: 30px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fc-label, .fc-label:hover {
  color: #5A5858;
  text-decoration: none;
}

.circle-green {
  border: 3px solid #00FF38;
}

/* Auth CSS */
.content-width {
  width: 390px;
}

@media (max-width: 767px) {
  .content-width {
    width: 370px;
  }
}

.border-login {
  padding: 28px;
  border-top-left-radius: 69px;
  border-top-right-radius: 69px;
  background-color: white;
}

.primary-button {
  background-color: #701E66;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px;
  height: 58px;
  cursor: pointer;
  font-weight: bold;
}

.primary-button-outlined {
  background-color: white;
  color: #701E66;
  border: 1px solid #701E66;
  border-radius: 30px;
  padding: 10px;
  height: 58px;
  cursor: pointer;
  font-weight: bold;
}

.input-login {
  height: 58px;
  background-color: #ECECEC;
  border: none;
  border-radius: 30px;
  padding: 10px 10px 10px 20px;
}

.title-auth {
  font-size: 30px;
  font-weight: bold;
  align-self: center;
}

.link-forgot-password {
  color: #5A5858;
  text-decoration: none;
  font-size: 12px;
}

.link-forgot-password:hover {
  color: #5A5858;
}

/* End Auth CSS */

@media screen and (max-width: 768px) {
  .fs-15 {
    font-size: 13px;
  }
  
  .fs-30 {
    font-size: 20px;
  }

  .button {
    width: 240px;
  }
}

.icon-xs {
  width: 12px;
  height: 12px;
}

.icon-sm {
  width: 16px;
  height: 16px;
}

.icon-md {
  width: 30px;
  height: 30px;
}

.icon-lg {
  width: 40px;
  height: 40px;
}

.icon-xl {
  width: 60px;
  height: 60px;
}

.color-paragraph {
  color: #6C6C6C;
}

.secondary-text {
  color: #6C6C6C;
}

.btn-success {
  background-color: #22A445;
  border: 0;
}

.line-height-normal {
  line-height: normal;
}

.color-white, .color-white:hover, .color-white:active, .color-white:visited {
  color: white;
}

input {
  font-size: 15px;
}

input::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-weight: lighter;
  color: #D2D2D2;
  font-size: 18px;
}

input:focus {
  outline: none;
}

.link-color {
  color: #0056B3 !important;
}

.w-85 {
  width: 85%;
}

.input-form {
  width: 100%;
  min-height: 40px;
  border: 1px solid #dfdfdf;
  border-radius: 13px;
  padding: 0 10px;
  background-color: #FFFFFF;
}

.btn-back-outline {
  background-color: #FFF;
  color: #D7D7D7;
  border: 1px solid #D7D7D7;
  border-radius: 13px;
  padding: 10px;
  cursor: pointer;
}

.form-check input:checked {
  background-color: #5A5858;
  border-color: #5A5858;
}

.card-rounded {
  border-radius: 40px;
}

.min-height-page {
  min-height: calc(100vh - 64px);
}

/* aqui começa o responsivo */

.btn-confirm-filled {
  width: 100%;
  background-color: #22A445;
  color: white;
  border: none;
  border-radius: 13px;
  padding: 15px 10px;
  cursor: pointer;
  font-size: 15px;
}

.credit-card-flag {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 2px;
  width: 25px;
  margin-right: 4px;
}

.padding-page {
  padding-left: 5%;
  padding-right: 5%;
}

.image-lg {
  width: 80px;
  height: 80px;
}

.fs-medium {
  font-size: 15px;
}

.fs-large {
  font-size: 17px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .credit-card-flag {
    width: 35px;
  }

  .content {
    width: 70%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .btn-confirm-filled {
    font-size: 20px;
  }

  .credit-card-flag {
    width: 45px;
  }

  .padding-page {
    padding-left: 15%;
    padding-right: 15%;
  }

  .image-lg {
    width: 130px;
    height: 130px;
  }

  .fs-medium {
    font-size: 16px;
  }

  .fs-large {
    font-size: 20px;
  }

  .content {
    width: 70%;
  }

  .min-height-auth {
    min-height: 600px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .content {
    width: 55%;
  }
}
</style>
