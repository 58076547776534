<template>
  <div class="padding-page d-flex flex-column align-self-start w-100">
    <NavBar />
  
    <div class="d-flex flex-column justify-content-center align-items-center align-self-center fs-30">
      <h2 class="fw-bold mt-4 text-center">{{ locale.plans.title }}</h2>
      <table class="table mt-3 mt-lg-4 border-right-1 fw-bolder">
        <thead>
          <tr>
            <th class="border-left-0 header-table fs-24 fw-bold" colspan="2" v-text="selectedPlan.name"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="fs-20 fw-bold w-30" v-text="locale.plans.table.concurrent_screens" />
            <td class="h-td border-left-1 text-center align-middle d-flex justify-content-center align-items-center">
              <button class="btn btn-light border-dark rounded-circle me-3 fw-bold quantity-button" @click="selectedPlan.screens--" :disabled="selectedPlan.screens === 1">
                -
              </button>
              <span class="fs-36">{{ selectedPlan.screens }}</span>
              <button class="btn btn-light border-dark rounded-circle ms-3 fw-bold quantity-button" @click="selectedPlan.screens++">
                +
              </button>
            </td>
          </tr>
          <tr class="border-top-0">
            <td class="fs-20 fw-bold secondary-text" v-text="locale.plans.table.included_firetv" />
            <td class="border-left-1 text-center secondary-text fs-20 align-middle">{{ selectedPlan.screens }}</td>
          </tr>
          <tr>
            <td class="fs-20 fw-bold secondary-text" v-text="locale.plans.table.daily_investment" />
            <td class="border-left-1 text-center secondary-text fs-20 align-middle">
              {{ currency(selectedPlan.daily_price) }}
            </td>
          </tr>
          <tr class="border-bottom-0">
            <td class="fs-20 fw-bold secondary-text" v-text="locale.plans.table.accounts_logged" />
            <td class="border-left-1 text-center secondary-text fs-20 border-horizontal-bottom-1-with-radius align-middle" v-text="locale.plans.table.ilimited" />
          </tr>
        </tbody>
      </table>

      <div class="d-flex align-items-center">
        <div class="d-flex flex-column secondary-text me-3">
          <span v-text="locale.plans.your_annual_plan" class="fs-20 fw-light" />
          <div class="d-flex fs-20">
            <span v-text="selectedPlan.billing_cycle + 'x '" />
            <span class="fw-bold ms-2" v-text="currency(selectedPlan.total / selectedPlan.billing_cycle)" />
          </div>
        </div>
        <button class="btn btn-success d-flex align-items-center ms-3 rounded-25 ps-4 pt-3 pb-3" @click="nextStep">
          <span class="fw-bold text-start line-height-normal" v-html="locale.plans.continue_your_buy"></span>
          <img src="@/assets/images/arrow-right-white.png" alt="arrow-right" class="ms-2" />
        </button>
      </div>

      <LoginModal :selectedPlan="selectedPlan" redirect="/checkout/shipping-address" />
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'
import { mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import api from '@/services/api'
import locale from '@/config/locales'

import NavBar from '@/components/NavBar.vue'
import { getCookie, setCookie } from '@/utils/cookies'
import LoginModal from '@/components/auth/LoginModal'
import Currency from '@/mixins/Currency'

export default {
  name: 'PlansView',
  data() {
    return {
      submitted: false,
      loading: false,
      plans: [],
      selectedPlan: {
        screens: 2
      },
      availableCards: [
        'mastercard',
        'visa',
        'elo',
        'hipercard',
        'amex',
        'diners',
        'cabal'
      ]
    }
  },
  components: {
    NavBar,
    LoginModal
  },
  created() {
    this.fetchPlans()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    }
  },
  methods: {
    async fetchPlans() {
      try {
        const response = await api.plans.all()
        this.plans = response.data
        this.selectedPlan = {
          ...this.selectedPlan,
          id: this.plans[0].id,
          name: this.plans[0].name,
          base_price: parseFloat(this.plans[0].base_price),
          billing_cycle: parseInt(this.plans[0].billing_cycle),
          additional_screen_price: parseFloat(this.plans[0].additional_screen_price),
          screens: 2,
          total: parseFloat(this.plans[0].base_price) * parseInt(this.plans[0].billing_cycle)
        }

        this.updatePrices()
        
        const plan = getCookie('selectedPlan')
        if (plan) {
          this.selectedPlan.screens = JSON.parse(plan).screens
        }
      } catch (error) {
        console.error('Erro ao buscar planos:', error)
      }
    },
    nextStep() {
      this.updatePrices()
      setCookie('selectedPlan', JSON.stringify({
        id: this.selectedPlan.id,
        name: this.selectedPlan.name,
        screens: this.selectedPlan.screens,
        base_price: parseFloat(this.selectedPlan.base_price),
        billing_cycle: parseInt(this.selectedPlan.billing_cycle),
        additional_screen_price: parseFloat(this.selectedPlan.additional_screen_price),
        total: this.selectedPlan.total
      }))

      if (!this.currentUser?.id) {
        this.openLoginModal()
        return
      }

      this.$router.push('/checkout/shipping-address')
    },
    updatePrices() {
      const price_screens = ((this.selectedPlan.screens - 1) * parseFloat(this.selectedPlan.additional_screen_price))
      this.selectedPlan.total = (this.selectedPlan.base_price + price_screens) * this.selectedPlan.billing_cycle
      this.selectedPlan.daily_price = (this.selectedPlan.total / 365).toFixed(2)
    },
    cardIcon(card) {
      return require(`@/assets/images/cards/${card}.png`)
    },
    openLoginModal() {
      const loginModal = new window.bootstrap.Modal(document.getElementById('loginModal'), {
        keyboard: false
      })
      loginModal.show()
    }
  },
  setup () {
    const customer_name = ref('')
    const customer_email = ref('')
    const tax_id = ref('')
    const card_number = ref('')
    const card_holder_name = ref('')
    const card_expiration_date = ref('')
    const card_cvv = ref('')

    const rules = {
      customer_name: {
        required: helpers.withMessage('Informe o nome completo', required)
      },
      customer_email: {
        required: helpers.withMessage('Informe o e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      tax_id: {
        required: helpers.withMessage('Informe um CPF', required)
      },
      card_number: {
        required: helpers.withMessage('Informe o número do cartão', required)
      },
      card_holder_name: {
        required: helpers.withMessage('Informe o nome do titular', required)
      },
      card_expiration_date: {
        required: helpers.withMessage('Informe a validade do cartão', required)
      },
      card_cvv: {
        required: helpers.withMessage('Informe o CVV', required)
      }
    }

    const v$ = useVuelidate(rules, {
      customer_name,
      customer_email,
      tax_id,
      card_number,
      card_holder_name,
      card_expiration_date,
      card_cvv
    })

    return { v$ }
  },
  watch: {
    'selectedPlan.screens': function () {
      this.updatePrices()
    }
  },
  mixins: [
    Currency
  ]
}
</script>

<style scoped>
.border-0 {
  border: 0;
}

.border-top-0 {
  border-top: 0;
}

.border-left-0 {
  border-left: 0;
}

.border-bottom-0 > td {
  border-bottom: 0;
}

.border-left-1 {
  border-left: 1px solid #dee2e6;
}

.border-right-1 {
  border-right: 1px solid #dee2e6;
  border-bottom-right-radius: 32px;;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 490px;
}

td:last-child.border-horizontal-bottom-1-with-radius {
  border-bottom: 1px solid #dee2e6 !important;
  border-bottom-left-radius: 32px !important;
  border-bottom-right-radius: 32px !important;
}

.header-table {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  background-color: #701E66;
  color: #FFF;
  text-align: center;
  padding-top: 17px;
  padding-bottom: 17px;
}

.quantity-button {
  width: 38px;
  height: 38px;
}

.rounded-25 {
  border-radius: 25px;
}

.h-td {
  height: 77px;
}
</style>