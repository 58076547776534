<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white z-3s">
      <NavBar :showMenu="false" :internalMenu="true" />
    </div>
    <div class="padding-page d-flex justify-content-center align-items-center background-silver w-100 min-height-page">
      <Loading v-if="loading" />
      <AccountCard
        v-else-if="accounts"
        :accounts="accounts"
        :current-account="current_account"
        @create-account="createAccount"
        @delete-account="deleteAccount"
        @show-message-box="showMessageBox"
        @close-message-box="closeMessageBox"
      />
      <AccountCard v-else />
      <MessageBox
        :visible="messageBox.visible"
        @close="messageBox.close"
        @confirm="messageBox.confirm"
        :message="messageBox.message"
        :buttonConfirmText="messageBox.buttonConfirm"
        :buttonCloseText="messageBox.buttonClose"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import AccountCard from './components/AccountCard'
import Loading from '@/components/Loading'
import { deleteCookie, clearAllIntervals } from '@/utils/cookies'
import MessageBox from '@/components/MessageBox'
import locale from '@/config/locales'
import NavBar from '@/components/NavBar'

export default {
  name: 'AccountsView',
  components: {
    AccountCard,
    Loading,
    MessageBox,
    NavBar
  },
  data() {
    return {
      accounts: [],
      current_account: 0,
      loading: true,
      messageBox: {
        visible: false,
        message: ''
      }
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }

    deleteCookie('authorization_code')
    clearAllIntervals()

    if (this.$route.query.authorization_code) {
      api.auth.setVuex({
        authorization_code: this.$route.query.authorization_code,
        token: localStorage.getItem('jwt-token'),
        vuex: localStorage.getItem('vuex')
      })
    }

    if (this.$route.query.code) {
      this.addAccounts(
        this.$route.query.code,
        this.$route.query.state
      )
    } else {
      this.fetchAccounts()
    }

    const closeMessage = () => { this.messageBox.visible = false }

    if (this.$route.query.message) {
      const isFullMessage = this.$route.query.message.includes(' ')
      this.messageBox.type = isFullMessage ? 'default' : this.$route.query.message
      this.messageBox.buttonConfirm = isFullMessage ? locale.understood : locale[this.$route.query.message].buttonConfirm || locale.understood
      this.messageBox.visible = true
      this.messageBox.message = isFullMessage ? this.$route.query.message : locale[this.$route.query.message].message
      this.messageBox.confirm = () => {
        const functionConfirm = locale[this.messageBox.type].confirm
        if (functionConfirm) functionConfirm()

        closeMessage()
      }

      this.messageBox.close = () => {
        this.messageBox.visible = false
      }

      this.$router.replace({ query: {} })
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    fetchAccounts() {
      this.loading = true
      api.accounts.get()
        .then(response => {
          this.accounts = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteAccount(id) {
      this.loading = true
      api.accounts.delete(id)
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    },
    createAccount(name) {
      this.loading = true
      api.accounts.create({ name })
        .then(() => {
          this.fetchAccounts()
        })
        .catch(() => {
          this.loading = false
        })
    },
    addAccounts(code, state) {
      api.accounts.callback({ code, state })
        .then(() => {
          this.fetchAccounts()
        })
    },
    showMessageBox({ title, message, type, buttonConfirm, buttonClose, confirm, close }) {
      this.messageBox.visible = true
      this.messageBox.title = title
      this.messageBox.message = message
      this.messageBox.type = type
      this.messageBox.buttonConfirm = buttonConfirm
      this.messageBox.buttonClose = buttonClose
      this.messageBox.confirm = confirm
      this.messageBox.close = close
    },
    closeMessageBox() {
      this.messageBox.visible = false
    }
  }
}
</script>