<template>
  <span class="title-auth">{{  locale.forgot_password.title }}</span>
  <form @submit.prevent="submitForm" class="p-4 d-flex flex-column" v-if="showForm">
    <input :placeholder="locale.user.email" type="email" v-model="v$.userEmail.$model" class="input-login" />
    <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
    <button type="submit" class="primary-button mt-4 d-flex align-items-center justify-content-center">
      <span class="fw-bold fs-15" v-if="!loading">
        {{  locale.forgot_password.button }}
      </span>
      <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
        <span class="visually-hidden"></span>
      </div>
    </button>

    <button type="button" @click="redirectToLogin" class="btn btn-link mt-4 link-forgot-password fw-bold">
      {{  locale.forgot_password.back_to_login }}
    </button>
  </form>
  <div v-else>
    <div class="d-flex flex-column align-items-center mt-3">
      <span class="fs-15 fw-bold" v-html="locale.forgot_password.success"></span>
    </div>
    <div class="d-flex flex-column">
      <button type="button" class="primary-button mt-4 d-flex align-items-center justify-content-center" @click="redirectToLogin">
        <span class="fw-bold fs-15" v-text="locale.forgot_password.back_to_login"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import api from '@/services/api'
import locale from '@/config/locales'

export default {
  name: 'FormForgotPassword',
  data() {
    return {
      showForm: true,
      submitted: false,
      loading: false
    }
  },
  computed: {
    locale() {
      return locale
    }
  },
  methods: {
    async submitForm() {
      if (this.loading) return

      this.loading = true

      try {
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return

        const params = {
          email: this.v$.userEmail.$model
        }

        const response = await api.auth.forgotPassword(params)

        if (response.status === 200) {
          this.showForm = false
        }
      } catch (error) {
        console.error('Erro ao enviar solicitação de recuperação de senha:', error)
      } finally {
        this.loading = false
      }
    },
    redirectToLogin() {
      this.$emit('goto-sign-in')
    },
  },
  setup () {
    const userEmail = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail
    })

    return { v$ }
  }
}
</script>