import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createHead } from '@vueuse/head'
import store from './store'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueTheMask from 'vue-the-mask'
import * as Sentry from '@sentry/vue'
import VueApexCharts from 'vue3-apexcharts'

const head = createHead()

const app = createApp(App).use(head)
                          .use(router)
                          .use(store)
                          .use(VueTheMask)
                          .use(VueApexCharts)
                          .component(VueQrcode.name, VueQrcode)

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.meustory\.tv/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
app.mount("#app");