<template>
  <div class="header d-flex justify-content-between align-items-center">
    <a href="/" class="d-flex align-items-center">
      <img
        src="../assets/images/logo_horizontal_purple.png" 
        alt="MeuStory.TV" 
        width="160" 
        height="28" 
      />
    </a>
    
    <ProfileButton v-if="internalMenu" />

    <button class="btn d-md-none" @click="toggleMenu" v-if="showMenu">
      <img src="../assets/images/bars.png" alt="menu" width="24" height="24" />
    </button>
    
    <div v-if="showMenu" class="menu d-none d-md-flex align-items-center">
      <router-link to="/" class="btn text-decoration-none secondary-text">
        <span v-text="locale.breadcrumbs.about" />
      </router-link>
      <router-link to="/plans" class="btn text-decoration-none secondary-text">
        <span v-text="locale.breadcrumbs.prices" />
      </router-link>
      <button class="btn text-decoration-none secondary-text" @click="goToWhatsApp">
        <span v-text="locale.breadcrumbs.talk_whatsapp" />
      </button>
      <span class="secondary-text fs-20">|</span>
      <button class="btn text-decoration-none secondary-text" @click="goToAccounts" v-if="currentUser?.id">
        <span v-text="locale.breadcrumbs.my_account" />
      </button>
      <button class="btn text-decoration-none secondary-text" @click="login" v-else>
        <span v-text="locale.breadcrumbs.login" />
      </button>
    </div>
  
    <div v-if="isMenuOpen && showMenu" class="mobile-menu d-md-none">
      <router-link to="/" class="btn text-decoration-none secondary-text" @click="toggleMenu">
        <span v-text="locale.breadcrumbs.about" />
      </router-link>
      <router-link to="/plans" class="btn text-decoration-none secondary-text" @click="toggleMenu">
        <span v-text="locale.breadcrumbs.prices" />
      </router-link>
      <button class="btn text-decoration-none secondary-text" @click="goToWhatsApp">
        <span v-text="locale.breadcrumbs.talk_whatsapp" />
      </button>
      <hr class="w-100" />
      <button class="btn text-decoration-none secondary-text" @click="goToAccounts" v-if="currentUser?.id">
        <span v-text="locale.breadcrumbs.my_account" />
      </button>
      <button class="btn text-decoration-none secondary-text" @click="login" v-else>
        <span v-text="locale.breadcrumbs.login" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { onSignout } from '@/services/api'
import locale from '@/config/locales'
import ProfileButton from './ProfileButton';

export default {
  name: 'NavBar',
  data() {
    return {
      isMenuOpen: false
    }
  },
  components: {
    ProfileButton
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true
    },
    internalMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    }
  },
  methods: {
    ...mapState('auth', ['setCurrentUser']),
    logout() {
      this.setCurrentUser({})
      onSignout()
      this.$router.go()
    },
    login() {
      this.$router.push('/sign-in')
    },
    goToWhatsApp() {
      window.open('https://wa.me/5551999153493?text=Quero%20come%C3%A7ar%20usar%20o%20MeuStory.TV', '_blank')
    },
    goToAccounts() {
      this.$router.push('/accounts')
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>

<style scoped>
.header {
  height: 64px;
}

.mobile-menu {
  position: absolute;
  top: 64px;  
  right: 0;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  width: 50vw;
  max-width: 400px;
}

.mobile-menu .btn {
  width: 100%;
  text-align: left;
}
</style>
