
<template>
  <div class="d-flex flex-column w-100 h-100">
    <div class="padding-page d-flex flex-column align-self-start w-100">
      <NavBar />
    </div>
    <div class="d-flex flex-column justify-content-start background-home align-items-center py-5">
      <h4 class="fw-bold fs-large color-white mt-lg-3">{{ locale.home.title }}</h4>
      <iframe class="video-home" src="https://www.youtube.com/embed/6N-JenOnmgI?si=RGwlZw8HZ5723ses" title="Video: Como funciona o MeuStory.tv" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <a href="/plans" class="mt-4 btn-confirm-filled btn-continue-home d-flex align-items-center justify-content-center text-decoration-none color-white">
        <span class="me-2 fs-medium fw-bold">
          {{ locale.home.continue }}
        </span>
        <img src="../../assets/images/arrow-right-white.png" alt="Prosseguir" width="24" height="24" />
      </a>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import locale from '@/config/locales'
import Footer from '@/components/Footer'

export default {
  name: 'HomeView',
  components: {
    NavBar,
    Footer
  },
  computed: {
    locale() {
      return locale
    }
  }
}
</script>

<style scoped>
.background-home {
  height: 100%;
  min-height: 500px;
  background: linear-gradient(135deg, #C061A8 -60%, #701E66 100%);
}

.video-home {
  margin-top: 20px;
  border: 4px solid white;
  border-radius: 50px;
  width: 80%;
  height: 45vw;
}

.btn-continue-home {
  margin-top: 20px;
  width: 85vw;
}

@media (min-width: 768px) {
  .video-home {
    height: 50vw;
  }

  .btn-continue-home {
    width: 42vw;
  }
}

@media (min-width: 1200px) {
  .btn-continue-home {
    width: 32vw
  }

  .video-home {
    width: 1180px;
    height: 630px;
  }
}
</style>