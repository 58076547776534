<template>
  <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="d-flex justify-content-center">
      <div class="modal-dialog modal-bottom">
        <div class="modal-content">
          <div class="modal-body d-flex flex-column align-items-center text-center">
            <Auth :selectedPlan="selectedPlan" :redirect="redirect" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from './Auth'
export default {
  name: 'LoginModal',
  components: {
    Auth
  },
  props: {
    selectedPlan: {
      type: Object,
      default: () => ({})
    },
    redirect: {
      type: String,
      default: '/accounts'
    }
  }
}
</script>

<style scoped>
.modal-bottom {
  position: fixed;
  bottom: 0;
  margin: 0;
  width: 100%;
  max-width: 500px;
}

.modal-content {
  border-radius: 32px 32px 0 0;
}
</style>
