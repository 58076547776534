<template>
  <footer class="d-flex flex-column align-items-center w-100 background-footer">
    <div class="d-flex flex-column align-items-center w-100">
      Copyright © 2025 - MeuStory.TV
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.background-footer {
  background: #701E66;
  color: white;
  padding: 20px 0;
}
</style>