<template>
  <div>
    <!-- Menu para Desktop -->
    <div class="sidebar d-none d-xl-flex">
      <router-link to="/accounts" class="text-decoration-none secondary-text fs-medium">
        <img src="@/assets/icons/angle-small-left.png" :alt="locale.account.sidebar.back" class="me-2 icon-md" />
        <span v-text="locale.account.sidebar.title_back" />
      </router-link>

      <span class="fs-medium fw-bold mt-5 secondary-text" v-text="locale.control_panel.title" />

      <router-link
        to="/admin/accounts"
        class="d-flex justify-content-start align-items-center text-decoration-none secondary-text fs-medium mt-3 menu-item w-100 py-2 px-2"
      >
        <img :src="iconMenu('users')" :alt="locale.control_panel.sidebar.accounts" class="me-2 icon-md" />
        <span :class="isAccountsPage ? 'fw-bold' : ''" v-text="locale.control_panel.sidebar.accounts" />
      </router-link>
    </div>

    <!-- Menu retraído para Mobile -->
    <div class="mobile-menu d-flex d-xl-none padding-page">
      <!-- Botão de toggle (menu hamburger) -->
      <button @click="toggleMenu" class="menu-toggle btn btn-link p-0">
        <img src="@/assets/images/bars.png" alt="Menu" class="icon-md" />
      </button>
    </div>
    <div
      v-if="isMenuOpen"
      class="mobile-sidebar padding-page"
      v-click-outside="closeMenu"
    >
      <span class="fs-medium fw-bold mt-5 secondary-text" v-text="locale.control_panel.title" />

      <router-link
        to="/admin/accounts"
        class="d-flex justify-content-start align-items-center text-decoration-none secondary-text fs-medium mt-3 menu-item w-100 py-2 px-2"
        @click="toggleMenu"
      >
        <img :src="iconMenu('users')" :alt="locale.control_panel.sidebar.accounts" class="me-2 icon-md" />
        <span :class="isAccountsPage ? 'fw-bold' : ''" v-text="locale.control_panel.sidebar.accounts" />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locale from '@/config/locales'

export default {
  name: 'SideBar',
  data() {
    return {
      isMenuOpen: false
    }
  },
  directives: {
    clickOutside: {
      bind(el, binding) {
        el.clickOutsideEvent = function(event) {
          // Se o clique for fora do elemento, chama a função de fechamento
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    },
    currentPath() {
      return this.$route.path
    },
    isAccountsPage() {
      return this.currentPath.includes('/accounts')
    }
  },
  methods: {
    ...mapState('auth', ['setCurrentUser']),
    login() {
      this.$router.push('/login')
    },
    goToWhatsApp() {
      window.open('https://wa.me/5511999999999', '_blank')
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    },
    iconMenu(menu) {
      if (menu === 'users') {
        return require(`@/assets/icons/users-${this.isAccountsPage ? 'filled' : 'outlined'}.png`)
      }
    }
  }
}
</script>

<style scoped>
/* Estilos para o menu de desktop */
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 325px;
  height: 100%;
  padding: 20px;
  background-color: #f8f9fa;
}

.menu-item {
  width: 100%;
  border: 1px solid transparent;
}

.menu-item:hover {
  border: 1px solid #5A5858;
  border-radius: 10px;
}

.mobile-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1100;
}

.menu-toggle {
  background: none;
  border: none;
  outline: none;
}

.mobile-sidebar {
  position: absolute;
  top: 67px;
  right: 7%;
  width: 300px;
  height: calc(100% - 80px);
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

@media (min-width: 992px) {
  .mobile-sidebar {
    right: 16%;
  }
}

@media (min-width: 1200px) {
  .mobile-sidebar {
    display: none;
  }
}

.icon-md {
  width: 24px;
  height: 24px;
}
</style>
