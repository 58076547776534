<template>
  <div class="padding-page d-flex flex-column align-self-start w-100">
    <NavBar />
  
    <div class="d-flex flex-column justify-content-center align-self-center pt-5">
      <img
        :src="require(`@/assets/images/${message.image}.png`)"
        alt="Finish"
        class="image-lg align-self-center"
      />
      <span v-html="message.title" class="fs-medium fw-bolder mb-1 mt-4 text-center" />
      <p v-html="message.subtitle" class="fs-medium mb-3 text-center mt-2" />

      <button class="btn button-primary mt-4 col-6 border-rounded align-self-center fs-15 fw-bold btn-large" @click="redirectTo">
        {{ message.button }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import locale from '@/config/locales'

import NavBar from '@/components/NavBar.vue'

export default {
  name: 'FinishView',
  components: {
    NavBar
  },
  created() {
    if (!this.$route.query.status) {
      this.$router.push({ name: 'plans' })
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    },
    message() {
      switch (this.$route.query.status.toUpperCase()) {
        case 'AUTHORIZED':
          return this.locale.finish.authorized
        case 'PAID':
          return this.locale.finish.paid
        case 'IN_ANALYSIS':
          return this.locale.finish.in_analysis
        case 'DECLINED':
          return this.locale.finish.declined
        case 'CANCELED':
          return this.locale.finish.canceled
        case 'WAITING':
          return this.locale.finish.waiting
        default:
          return ''
      }
    },
    status() {
      return this.$route.query.status
    }
  },
  methods: {
    redirectTo() {
      this.$router.push(this.message.redirect)
    }
  }
}
</script>

<style scoped>
.border-rounded {
  border-radius: 23px;
}

.btn-large {
  width: auto;
  height: 58px;
  border-radius: 30px;
  padding-left: 45px;
  padding-right: 45px;
}
</style>