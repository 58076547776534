<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white">
      <NavBar :showMenu="false" />
    </div>
    <div class="padding-page d-flex justify-content-start background-silver py-5 w-100 min-height-page">
      <SideBar />
      <div class="d-flex flex-column fs-30 w-100 align-items-start">
        <h2 class="fw-bold mt-4 text-center">{{ locale.account.user.title }}</h2>

        <!-- Atualizar meus dados -->
        <!-- <div class="row w-100 mt-3">
          <button class="d-flex align-items-center col-10 bg-white card-rounded p-3 border-0 w-100">
            <img src="../../assets/icons/edit.png" :alt="locale.account.user.change_password" class="icon-md ms-3" />
            <span class="fs-medium ms-3">{{ locale.account.user.edit }}</span>
          </button>
        </div> -->

        <div class="row w-100 mt-3">
          <button class="d-flex align-items-center col-10 bg-white card-rounded p-3 border-0 w-100" @click="changePassword">
            <img src="../../assets/icons/lock.png" :alt="locale.account.user.change_password" class="icon-md ms-3" />
            <span class="fs-medium ms-3">{{ locale.account.user.change_password }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import SideBar from '@/components/SideBar'
import locale from '@/config/locales'
import api from '@/services/api'

export default {
  name: 'AccountView',
  components: {
    SideBar,
    NavBar
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    }
  },
  methods: {
    async changePassword() {
      console.log(this.currentUser)
      const response = await api.auth.forgotPassword({
        email: this.currentUser.email
      })

      if (response.status === 200) {
        alert('Um e-mail para recuperação de senha foi enviado para você.')
      } else {
        alert('Erro ao enviar e-mail para recuperação de senha.')
      }
    }
  }
}
</script>
