<template>
  <div class="background-story-screen d-flex justify-content-center align-items-center" ref="content-account-show" @mousemove="onMouseMove" @mouseleave="onMouseLeave">
    <transition name="fade">
      <router-link to="/accounts" class="btn btn-link back-button" v-if="!loading && showButton" style="z-index: 9;" ref="back-button">
        <img src="@/assets/images/arrow-left-silver.png" alt="Voltar" />
      </router-link>
    </transition>
    <div class="background-story-screen d-flex justify-content-center align-items-center">
      <div v-for="n in quantity_show"
           class="content-story-screen"
           ref="items-story-screen"
           :key="n">
        <img
          v-if="!loading && isImage(showing[n].index)"
          :src="medias[showing[n].index].media_url"
          alt="midia"
          class="img-fluid"
          :class="classImgFluid"
          :ref="`view-image-${n}`"
          :id="medias[showing[n].index].id"
        />
        <video
          v-else-if="!loading && isVideo(showing[n].index)"
          :src="mediaLink(n)"
          alt="midia"
          :controls="false"
          autoplay
          muted
          class="img-fluid"
          :id="medias[showing[n].index].id"
          :class="classVideoFluid"
          :ref="`view-video-${n}`"
          preload="auto"
          @ended="() => nextMedia(n)"
          @loadedmetadata="() => setNextUpdate(n)"
        />
        <span class="spinner-border text-light" style="opacity: .5; position: absolute; left: 50%; top: 50%; color: #FFF;" v-if="loading || showing[n].updating"></span>
        <img src="@/assets/images/horizontal_with_border.svg"
             alt="MeuStory.TV"
             class="logo-bottom"
             :class="classLogo"
             v-if="n == quantity_show && !loading"
             ref="logo-bottom"
        />
        <img src="@/assets/images/qrcode.png"
              alt="MeuStory.TV"
              class="qrcode-bottom"
              :class="classQrCode"
              v-if="reseller && !loading && n == 1"
              ref="qrcode-bottom"
        />
      </div>
      <button class="btn-fullscreen" @click="toggleFullscreen" v-if="showButton" ref="toggle-full-screen">
        <img src="@/assets/images/fullscreen.png" alt="Tela cheia" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import { clearAllIntervals } from '@/utils/cookies'
import SecureRandom from '@/mixins/SecureRandom'
import * as Sentry from '@sentry/vue'

const MEDIA_TYPES = {
  image: 'IMAGE',
  video: 'VIDEO'
}

const connectionErrors = [
  'ERR_NETWORK',
  'ERR_INTERNET_DISCONNECTED',
  'ERR_CONNECTION_RESET',
  'ERR_CONNECTION_CLOSED',
  'ERR_CONNECTION_REFUSED',
  'ERR_CONNECTION_ABORTED',
  'ERR_NAME_NOT_RESOLVED',
  'ERR_CONNECTION_TIMED_OUT',
  'ERR_SSL_PROTOCOL_ERROR',
  'ERR_PROXY_CONNECTION_FAILED',
  'ERR_TUNNEL_CONNECTION_FAILED',
  'ERR_ADDRESS_UNREACHABLE',
  'ECONNABORTED',
  'ECONNREFUSED'
]

const MIN_MEDIA_MORE_ONE = 4 // min 4 medias to show more than one screen
const TIME_TO_LOAD = 2 // seconds

export default {
  name: 'AccountsShowView',
  data() {
    return {
      medias: [],
      loading: true,
      searchingMedia: false,
      account: {
        view: 0
      },
      quantity_show: 1,
      showing: {
        1: {
          index: 0,
          updated_at: new Date().setSeconds(-2),
          next_update: new Date().setSeconds(-1),
          updating: false
        },
      },
      reels: [],
      showButton: false,
      currentIndex: 0,
      countView: 1,
      sessionId: null,
      responseStories: {},
      responseReels: {},
      retry: 0,
      maxRetries: 1000,
      heartbeatInterval: null,
      downloadedMedias: [],
    }
  },
  async created() {
    window.scrollTo(0, document.body.scrollHeight)
    
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    }
    
    try {
      this.currentIndex = parseInt(this.$route.query.index) || 0
    } catch (error) {
      this.currentIndex = 0
    }

    await this.fetchAccount().then(response => {
      this.account = response.data
    })

    await this.fetchMedias()

    this.mountShowing()

    for (let i = 0; i < this.quantity_show; i++) {
      const position = i + 1
      const media = this.medias[i]
      this.showing[position].updating = true

      setTimeout(() => {
        try {
          const duration = this.getDuration(media.media_type, position)
          this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + TIME_TO_LOAD + ((duration + 1000) / 1000)))

          if (this.isImage(this.showing[position].index)) {
            setTimeout(() => {
              this.nextMedia(position)
            }, duration)
          }

          this.watchNextUpdate(position)
        } finally {
          this.showing[position].updating = false
        }
      }, 1000)
    }

    this.loading = false

    this.$nextTick(() => {
      if (this.$refs['logo-bottom'] && this.$refs['logo-bottom'][0]) {
        this.$refs['logo-bottom'][0].style.opacity = this.account.logo_opacity + '%'
      }
    })

    window.addEventListener('beforeunload', this.closeSession)
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyPress)
  },
  computed: {
    mediaTypes() {
      return MEDIA_TYPES
    },
    classImgFluid() {
      return this.isVertical ? `img-fluid-vertical rotate-${this.account.degrees}` : 'img-fluid-33'
    },
    classVideoFluid() {
      return this.isVertical ? 'video-fluid-vertical' : 'img-fluid-33'
    },
    isVertical() {
      return this.account.degrees > 0
    },
    classLogo() {
      const { degrees } = this.account

      return degrees == 90 ? 'logo-90' : degrees == 270 ? 'logo-270' : 'logo-bottom-horizontal'
    },
    classQrCode() {
      const { degrees } = this.account

      return degrees == 90 ? 'qrcode-90' : degrees == 270 ? 'qrcode-270' : 'qrcode-bottom-horizontal'
    },
    onlyStory() {
      return this.account.view === 'stories'
    },
    onlyReels() {
      return this.account.view === 'reels'
    },
    reseller() {
      const resellers = ['labfecomerciors', 'claconexoes']
      return resellers.includes(this.account.name)
    },
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "ArrowUp") {
        this.toggleFullscreen()
      }
    },
    fetchAccount() {
      return api.accounts.getById(this.$route.params.id)
    },
    async fetchMedias() {
      const data = []

      if (!this.onlyReels) {
        const date = new Date()
        const response = await api.accounts.stories(this.$route.params.id, this.sessionId).catch(error => {
          Sentry.captureException({
            account: this.account,
            error,
            message: '[XHR] Erro ao carregar stories'
          }, 'warning')

          return {
            data: this.stories
          }
        })

        if (connectionErrors.includes(response.code)) {
          if (this.retry < this.maxRetries) {
            this.retry++
            setTimeout(() => this.fetchMedias(), 5000)
          } else {
            this.$router.push({
              path: '/accounts',
              query: {
                message: 'err_network'
              }
            })
          }

          data.push(...this.stories)
        } else {
          this.retry = 0
          this.responseStories = response

          if (response?.response?.status > 200) {
            this.redirectError(response)
            return
          }

          this.stories = response.data || []

          if (this.stories.length > 0) {
            date.setHours(date.getHours() - this.account.story_hour_limit)
  
            const filteredStories = this.stories.filter(story => new Date(story.timestamp) >= date) // colocar essa regra no back
            data.push(...filteredStories)
          }
        }
      }

      if (!this.onlyStory) {
        if (this.reels.length === 0) {
          const response = await api.accounts.reels(this.$route.params.id, this.sessionId).catch(error => {
            return error
          })

          if (!connectionErrors.includes(response.code)) {
            this.responseReels = response

            if (response?.response?.status > 200) {
              this.redirectError(response)
              return
            }

            this.reels = response.data
          }
        }

        data.push(...this.reels)
      }

      if (data.length > MIN_MEDIA_MORE_ONE) this.quantity_show = this.account.quantity_show

      if (data.length === 0) {
        Sentry.captureException({
          userId: this.$route.params.id,
          account: this.account,
          responseStories: this.responseStories,
          responseReels: this.responseReels,
          message: 'no_medias'
        }, 'warning')

        this.$router.push({
          path: '/accounts',
          query: {
            message: 'no_medias'
          }
        })
        return
      }

      data.forEach(async (media) => {
        const mediaAlreadyDownloaded = this.downloadedMedias.find(downloadedMedia => downloadedMedia.id === media.id)
        if (mediaAlreadyDownloaded) {
          media.media_url = mediaAlreadyDownloaded.media_url
        } else {
          media.media_url = await api.videos.download(media.media_url)
          this.downloadedMedias.push({
            id: media.id,
            media_url: media.media_url
          })

          if (media.type === 'story') {
            setTimeout(() => {
              URL.revokeObjectURL(media.media_url)
            }, 1000 * 60 * 60 * 25) // 25 hours
          }
        }
      })

      this.medias = data
    },
    async nextMedia(position) {
      if (this.searchingMedia) {
        setTimeout(() => {
          this.nextMedia(position)
        }, 100)
        return
      }

      try {
        this.searchingMedia = true
        this.showing[position].updating = true
        this.showing[position].reload = true
        const lastShow = Object.values(this.showing).reduce((max, current) =>
          current.updated_at > max.updated_at ? current : max
        )

        const hasMoreMedias = lastShow.index < this.medias.length - 1

        this.showing[position].updated_at = new Date()

        let newIndex
        if (hasMoreMedias) {
          newIndex = lastShow.index + 1
        } else {
          newIndex = 0
          await this.fetchMedias()
        }

        const newMedia = this.medias[newIndex]
        if (this.mediaPlaying(newMedia, position)) {
          this.searchingMedia = false
          this.showing[position].index = newIndex
          this.nextMedia(position)
          return
        }

        this.showing[position].index = newIndex

        if (this.isVideo(this.showing[position].index)) {
          setTimeout(() => {
            if (this.$refs[`view-video-${position}`] && this.$refs[`view-video-${position}`][0]) {
              if (this.$refs[`view-video-${position}`][0].paused) {
                this.$refs[`view-video-${position}`][0].load()
              }
            }
          }, 200)
        }

        setTimeout(() => {
          try {
            const index = this.showing[position].index
            const media = this.medias[index]
            const duration = this.getDuration(media.media_type, position)
  
            this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + TIME_TO_LOAD + (duration / 1000)))
  
            if (this.isVideo(index)) return
  
            setTimeout(() => {
              this.nextMedia(position)
            }, duration)
          } finally {
            this.showing[position].updating = false
          }
        }, 1000) // talvez esse setTimeout não seja suficiente, tentar fazer um retry caso não consiga pegar a duração do vídeo
      } finally {
        this.countView++
        this.currentIndex = this.showing[position].index
        this.searchingMedia = false
      }
    },
    mediaPlaying(media, position) {
      const positions = Object.values(this.showing).map(show => show.index)
      positions.splice(position - 1, 1)

      const ids = positions.map(position => this.medias[position] ? this.medias[position]?.id : 0)

      return ids.includes(media?.id)
    },
    getDuration(media_type, position) {
      let video

      if (media_type === MEDIA_TYPES.video) {
        video = this.$refs[`view-video-${position}`]
        video = video ? video[0] : null
      }

      const videoDuration = video?.duration ? Math.round(video.duration) : null
      return ((videoDuration || this.account.duration) * 1000) - 1250
    },
    isVideo(index) {
      if (!this.medias[index]) return

      return this.medias[index].media_type === MEDIA_TYPES.video
    },
    isImage(index) {
      if (!this.medias[index]) return

      return this.medias[index].media_type === MEDIA_TYPES.image
    },
    setNextUpdate(position) {
      const index = this.showing[position].index
      const media = this.medias[index]

      if (!media) return

      const duration = this.getDuration(media.media_type, position)

      this.showing[position].next_update = new Date(new Date().setSeconds(new Date().getSeconds() + duration / 1000))
      this.showing[position].updating = false
    },
    watchNextUpdate(position) {
      setInterval(() => {
        if (this.showing[position].updating) return

        const now = new Date().setSeconds(new Date().getSeconds() - 1)
        const nextUpdate = this.showing[position].next_update

        if (nextUpdate < now) this.nextMedia(position)
      }, 500)
    },
    redirectToAccounts() {
      this.$router.push('/accounts')
    },
    onMouseMove() {
      this.showButton = true;
      this.resetButtonTimer();
    },
    onMouseLeave() {
      this.showButton = false;
    },
    resetButtonTimer() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showButton = false;
      }, 3000);
    },
    toggleFullscreen() {
      if (!this.isFullscreen) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
      this.isFullscreen = !this.isFullscreen;
    },
    enterFullscreen() {
      let elem = document?.documentElement; 
      if (elem?.requestFullscreen) {
        elem?.requestFullscreen();
      } else if (elem?.mozRequestFullScreen) {
        elem?.mozRequestFullScreen();
      } else if (elem?.webkitRequestFullscreen) {
        elem?.webkitRequestFullscreen();
      } else if (elem?.msRequestFullscreen) {
        elem?.msRequestFullscreen();
      }
    },
    exitFullscreen() {
      try {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } catch (error) {
        console.error(error)
      }
    },
    mountShowing() {
      for (let i = 1; i <= this.quantity_show; i++) {
        this.showing[i] = {
          index: (this.currentIndex + i) - 1,
          updated_at: new Date(new Date().setSeconds(i*3-2)),
          next_update: new Date(new Date().setSeconds(i-1)),
          updating: false,
          reload: true
        }
      }
    },
    mediaLink(position) {
      const indexMedia = this.showing[position].index

      if (this.showing[position].lastIndexMedia !== indexMedia) {
        this.showing[position].lastIndexMedia = indexMedia
        this.showing[position].currentMedia = `${this.medias[indexMedia].media_url}`
      }

      return this.showing[position].currentMedia
    },
    redirectError(error) {
      const { status, data } = error.response
      const { error: message } = data

      if (status === 403) {
        this.$router.push({
          path: '/accounts',
          query: {
            message
          }
        })
        return
      } else if (status === 422) {
        this.$router.push({
          path: '/accounts',
          query: {
            message
          }
        })
        return
      } else if (status > 200) {
        Sentry.captureException({
          userId: this.$route.params.id,
          account: this.account,
          error
        }, 'warning')

        this.$router.push({
          path: '/accounts',
          query: {
            message: 'error_fetch_medias'
          }
        })
      }
    },
    initSession() {
      this.sessionId = this.sessionId || this.uuid()

      this.heartbeatInterval = setInterval(() => {
        this.sendHeartbeat()
      }, 5000)
    },
    sendHeartbeat() {
      api.sessions.heartbeat({ session_id: this.sessionId, account_id: this.$route.params.id })
        .catch(error => {
          console.error('Erro ao enviar heartbeat:', error)
        })
    },
    async closeSession() {
      clearInterval(this.heartbeatInterval)
      await api.sessions.close({ session_id: this.sessionId })
    }
  },
  watch: {
    $route: {
      handler() {
        clearAllIntervals()
        this.initSession()
      },
      immediate: true,
      deep: true
    }
  },
  beforeUnmount() {
    clearTimeout(this.timer)
    window.removeEventListener('beforeunload', this.closeSession)
    this.closeSession()
    this.medias.forEach(media => {
      URL.revokeObjectURL(media.media_url)
    })
  },
  mixins: [
    SecureRandom
  ]
}
</script>

<style scoped>
.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.background-story-screen {
  background-color: #1A1A1A;
  width: 100%;
  height: 100%;
}

.content-story-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.img-fluid {
  height: 100%;
  object-fit: cover;
}

.img-fluid-vertical {
  width: 100vh !important;
  height: 100vw !important;
}

.video-fluid-vertical {
  width: 100vw !important;
  height: 100vh !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.btn-fullscreen {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  background-color: transparent;
  border: none;
}

.btn-fullscreen img {
  width: 50px;
}

.logo-bottom {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.logo-bottom-horizontal {
  width: 11vw;
}

.logo-90 {
  position: absolute;
  width: 27vh;
  left: -20px;
  bottom: 15vh;
  rotate: 90deg;
}

.logo-270 {
  position: absolute;
  width: 27vh;
  top: 15vh;
  right: -20px;
  rotate: 270deg;
}

.left-60-percent {
  transform: translateX(-50%);
  left: 60%;
}

.img-fluid-33 {
  width: 33.33vw !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

/* Imagem para contratar no canto inferior esquerdo */
.qrcode-bottom {
  position: absolute;
  bottom: 20px;
}

.qrcode-bottom-horizontal {
  width: 14vw;
  left: 30px;
}

.qrcode-90 {
  position: absolute;
  width: 30vh;
  left: -20px;
  top: 15vh;
  rotate: 90deg;
}

.qrcode-270 {
  position: absolute;
  width: 30vh;
  bottom: 15vh;
  right: -20px !important;
  rotate: 270deg;
}

/* Fim da imagem para contratar no canto inferior esquerdo */
</style>
