<template>
  <span class="title-auth">{{ localeLogin.access }}</span>
  <form @submit.prevent="submitForm" class="p-4 d-flex flex-column">
    <input :placeholder="localeLogin.email" type="email" v-model="v$.userEmail.$model" class="input-login" />
    <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
    <input :placeholder="localeLogin.password" type="password" v-model="v$.password.$model" class="input-login mt-3" />
    <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
    <span v-if="error_message && submitted" class="color-danger fs-12 mt-3">{{ error_message }}</span>
    <button type="button" @click="goToForgotPassword" class="btn btn-link mt-2 align-self-end link-forgot-password fw-bold">
      {{ localeLogin.forgot_password }}
    </button>
    <button type="submit" class="primary-button mt-4 d-flex justify-content-center align-items-center">
      <span class="fw-bold fs-15">
        {{ localeLogin.enter }}
      </span>
    </button>
    <div class="d-flex justify-content-center align-items-center mt-3 fc-label fw-bold">
      <span class="fs-12">{{ localeLogin.dont_have_account }}</span><button @click="$emit('goto-sign-up')" type="button" class="btn btn-link fs-12 p-1 fw-bold">{{ localeLogin.click_here }}</button>
    </div>
  </form>
</template>

<script>
import { required, email, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'
import { mapActions, mapState } from 'vuex'
import api from '@/services/api'
import { clearAllIntervals, getCookie } from '@/utils/cookies'
import locale from '@/config/locales'

export default {
  name: 'FormSignIn',
  data () {
    return {
      submitted: false,
      error_message: ''
    }
  },
  props: {
    redirect: {
      type: String,
      default: '/accounts'
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    localeLogin () {
      return locale.login
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      try {
        const authorization_code = getCookie('authorization_code')
        this.submitted = true
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return
        const params = {
          email: this.v$.userEmail.$model,
          password: this.v$.password.$model,
          authorization_code
        }

        const response = await api.auth.signIn(params)

        const { data } = response

        await this.setCurrentUser(data)
        clearAllIntervals()
        if (authorization_code) {
          api.auth.setVuex({ authorization_code, vuex: localStorage.getItem('vuex') })
        }

        // eslint-disable-next-line
        $('#loginModal')?.modal('hide')

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
          return
        }

        this.$router.push(this.redirect)
      } catch (error) {
        console.error('Erro ao fazer login:', error)
        this.error_message = error.response.data.error[0]
      }
    },
    goToForgotPassword () {
      this.$emit('goto-forgot-password')
    },
    goToSignUp () {
      this.$emit('goto-sign-up')
    }
  },
  setup () {
    const userEmail = ref('')
    const password = ref('')

    const rules = {
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required)
      }
    }

    const v$ = useVuelidate(rules, {
      userEmail,
      password
    })

    return { v$ }
  }
}
</script>