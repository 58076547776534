import { createRouter, createWebHistory } from 'vue-router'

// import InitialView from '@/views/InitialView'
import SignInView from '@/views/auth/SignInView'
import SignUpView from '@/views/auth/SignUpView'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView'
import ChangePasswordView from '@/views/auth/ChangePasswordView'

// Home
import HomeView from '@/views/home/HomeView'

// Accounts
import AccountsView from '@/views/accounts/AccountsView'
import AccountShowView from '@/views/accounts/AccountShowView'
import AccountConfig from '@/views/accounts/AccountConfig'

// Privacy Policy
import PrivacyPolicyView from '@/views/PrivacyPolicyView'
import TermsOfServiceView from '@/views/TermsOfServiceView'

// Admin
import AdminView from '@/views/admin/AdminView'
import AdminAccountsView from '@/views/admin/AdminAccountsView'
import DashboardAccountView from '@/views/accounts/dashboard/DashboardAccountView.vue'

// Plan
import PlansView from '@/views/plans/PlansView'

// Checkout
import PaymentsView from '@/views/checkout/PaymentsView'
import FinishView from '@/views/checkout/FinishView'
import ShippingAddressView from '@/views/checkout/ShippingAddressView'
import ContractView from '@/views/ContractView'

// Account
import AccountView from '@/views/account/AccountView'
import PlanView from '@/views/account/PlanView'
import TutorialsView from '@/views/account/TutorialsView'

const routes = [
  {
    path: '/',
    name: 'initial',
    component: HomeView
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignInView,
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: SignUpView
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordView
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePasswordView
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: AccountsView
  },
  {
    path: '/accounts/:id/config',
    name: 'accounts-config',
    component: AccountConfig
  },
  {
    path: '/accounts/:id/show',
    name: 'accounts-show',
    component: AccountShowView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: TermsOfServiceView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/admin/accounts',
    name: 'admin-accounts',
    component: AdminAccountsView
  },
  {
    path: '/plans',
    name: 'plans',
    component: PlansView
  },
  {
    path: '/checkout/shipping-address',
    name: 'shipping-address',
    component: ShippingAddressView
  },
  {
    path: '/checkout/payment',
    name: 'payment',
    component: PaymentsView
  },
  {
    path: '/checkout/finish',
    name: 'checkout-finish',
    component: FinishView
  },
  {
    path: '/contract',
    name: 'contract',
    component: ContractView
  },
  {
    path: '/account/user',
    name: 'account-user',
    component: AccountView
  },
  {
    path: '/account/plan',
    name: 'account-plan',
    component: PlanView
  },
  {
    path: '/account/tutorials',
    name: 'account-tutorials',
    component: TutorialsView
  },
  {
    path: '/accounts/:account_id/dashboard',
    name: 'dashboard-account',
    component: DashboardAccountView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router