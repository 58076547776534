<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-row align-items-center content-width justify-content-center position-relative">
        <button @click="goToSignIn" class="btn btn-link back-link" type="button">
          <img src="@/assets/images/arrow-left.png" alt="Voltar" />
        </button>
      </div>
      <div class="margin-top-background-login border-login full-screen content-width d-flex flex-column">
        <FormSignUp @goto-sign-in="goToSignIn" redirect="/accounts" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCookie } from '@/utils/cookies'
import FormSignUp from '@/components/auth/FormSignUp'

export default {
  name: 'SignInView',
  data() {
    return {
      submitted: false,
      error_message: '',
      loading: false
    }
  },
  components: {
    FormSignUp
  },
  created() {
    if (this.currentUser) {
      this.$router.push('/accounts')
    }
  },
  computed: {
    ...mapState('auth', ['currentUser'])
  },
  methods: {
    goToSignIn() {
      this.$router.push({
        path: '/sign-in',
        query: {
          authorization_code: getCookie('authorization_code'),
          show_form: true
        }
      })
    }
  }
}
</script>