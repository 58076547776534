<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white z-3s">
      <NavBar :showMenu="false" />
    </div>
    <div class="padding-page d-flex justify-content-start background-silver py-5 w-100 min-height-page">
      <SideBar />
      <div class="d-flex flex-column fs-30 w-100 align-items-start">
        <div class="d-flex align-items-end">
          <span class="text-secondary fw-bold fs-12 text-center">{{ locale.control_panel.title }} > </span>
          <router-link to="/admin/accounts" class="text-secondary fw-bold fs-12 text-center ms-1 text-decoration-none">
            {{ locale.control_panel.sidebar.accounts }} >
          </router-link>
          <span class="ms-1 fw-bold fs-20 text-center ms-1 lh-sm">{{ locale.control_panel.accounts.monitoring }}</span>
        </div>

        <div class="w-100 text-center">
          <div class="d-flex flex-column col-12 bg-white card-rounded mt-3 pt-3 align-items-center text-start position-relative end-0 min-height-graph-350">
            <div class="d-flex align-items-center align-self-start fs-20 ps-5">
              <img :src="account.picture" alt="Foto de perfil" class="picture-profile me-2" />
              @{{ account.name }}
            </div>
            <SessionsByDayChart />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import SideBar from '@/views/admin/components/SideBar'
import api from '@/services/api'
import locale from '@/config/locales'
import SessionsByDayChart from './components/SessionsByDayChart'

export default {
  name: 'DashboardAccountView',
  components: {
    NavBar,
    SideBar,
    SessionsByDayChart
  },
  data() {
    return {
      account: {}
    }
  },
  created() {
    this.fetchAccount()
  },
  computed: {
    locale() {
      return locale
    }
  },
  methods: {
    async fetchAccount() {
      try {
        const response = await api.accounts.getByIdAdmin(this.$route.params.account_id)

        this.account = response.data
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
.picture-profile {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
</style>