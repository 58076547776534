import axios from 'axios'

export default () => ({
  download: async (videoUrl) => {
    const response = await axios.get(videoUrl, { responseType: 'blob' })
    const videoBlob = response.data

    return URL.createObjectURL(videoBlob)
  }
})
