<template>
  <div class="padding-page d-flex flex-column align-self-start w-100 pb-4">
    <NavBar />

    <div class="d-flex flex-column justify-content-center align-items-center align-self-center fs-30 fw-bolder">
      <h2 class="fw-bold mt-4 text-center" v-text="locale.shipping_address.title"></h2>
    
      <form @submit.prevent="submitForm" class="p-lg-4 d-flex flex-column container line-height-normal ">
        <div class="row d-flex justify-content-center">
          <div class="col-12 col-lg-8">
            <label for="zip_code" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.zip_code }}</label>
            <input type="text" v-model="v$.zip_code.$model" class="w-100 input-form" v-mask="'#####-###'" />
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-lg-1 mt-3">
          <div class="col-12 col-lg-8">
            <label for="address" class="ms-1 fs-16 fw-bold fc-label mb-1">{{ locale.shipping_address.address }}</label>
            <input type="text" v-model="v$.address.$model" class="w-100 input-form" />
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-lg-1 mt-3">
          <div class="col-6 col-lg-4">
            <label for="number" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.number }}</label>
            <input type="text" v-model="v$.number.$model" class="w-100 input-form" v-mask="'########'" />
          </div>
          <div class="col-6 col-lg-4">
            <label for="complement" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.complement }}</label>
            <input type="text" v-model="v$.complement.$model" class="w-100 input-form" />
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-lg-1 mt-3">
          <div class="col-12 col-lg-8">
            <label for="neighborhood" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.neighborhood }}</label>
            <input type="text" v-model="v$.neighborhood.$model" class="w-100 input-form" />
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-lg-1 mt-3">
          <div class="col-8 col-lg-6">
            <label for="city" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.city }}</label>
            <input type="text" v-model="v$.city.$model" class="w-100 input-form" />
          </div>
          <div class="col-4 col-lg-2">
            <label for="state" class="ms-1 fs-16 fw-bold fc-label">{{ locale.shipping_address.state }}</label>
            <select v-model="v$.state.$model" class="w-100 input-form select-input">
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-lg-8 d-flex flex-column">
            <span v-if="v$.zip_code.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.zip_code.$errors[0]?.$message }}</span>
            <span v-if="v$.address.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.address.$errors[0]?.$message }}</span>
            <span v-if="v$.number.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.number.$errors[0]?.$message }}</span>
            <span v-if="v$.complement.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.complement.$errors[0]?.$message }}</span>
            <span v-if="v$.neighborhood.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.neighborhood.$errors[0]?.$message }}</span>
            <span v-if="v$.city.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.city.$errors[0]?.$message }}</span>
            <span v-if="v$.state.$errors.length && submitted" class="color-danger fs-12 mt-1">- {{ v$.state.$errors[0]?.$message }}</span>
            <span v-if="error_message" class="color-danger fs-12 mt-1">{{ error_message }}</span>
            <div class="d-flex flex-column justify-space-between mt-4">
              <button type="submit" class="w-100 btn-confirm-filled d-flex justify-content-center align-items-center">
                <span class="fw-bold fs-15" v-if="!loading">
                  {{ locale.shipping_address.continue }}
                </span>
                <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
                  <span class="visually-hidden"></span>
                </div>
              </button>

              <button type="button" class="btn btn-link fc-label mt-3 d-flex justify-content-center align-items-center" @click="goBack">
                <span class="fw-bold fs-15">
                  {{ locale.shipping_address.back }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, helpers } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { reactive } from 'vue'
import NavBar from '@/components/NavBar'
import { getCookie } from '@/utils/cookies'
import { mapActions, mapState } from 'vuex'
import locale from '@/config/locales'
import api from '@/services/api'

export default {
  name: 'ShippingAddress',
  components: {
    NavBar
  },
  mounted() {
    const plan = getCookie('selectedPlan')

    if (!this.currentUser || !plan) {
      this.$router.push('/plans')
      return
    }

    if (this.currentUser) {
      this.v$.zip_code.$model = this.currentUser.zip_code
      this.v$.address.$model = this.currentUser.address
      this.v$.number.$model = this.currentUser.number
      this.v$.complement.$model = this.currentUser.complement
      this.v$.neighborhood.$model = this.currentUser.neighborhood
      this.v$.city.$model = this.currentUser.city
      this.v$.state.$model = this.currentUser.state
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    }
  },
  data() {
    return {
      submitted: false,
      error_message: ''
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      this.submitted = true
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      const paramsAddress = {
        zip_code: this.v$.zip_code.$model.replace('-', ''),
        address: this.v$.address.$model,
        number: this.v$.number.$model,
        complement: this.v$.complement.$model,
        neighborhood: this.v$.neighborhood.$model,
        city: this.v$.city.$model,
        state: this.v$.state.$model
      }

      const response = await api.users.updateAddress({
        email: this.currentUser.email,
        ...paramsAddress
      })

      if (response.status !== 200) {
        this.error_message = response.data.error[0]
        return
      }

      this.setCurrentUser({
        user : {
          ...this.currentUser,
          ...paramsAddress
        }
      })

      this.$router.push('/checkout/payment')
    },
    goBack() {
      this.$router.push('/plans')
    }
  },
  setup() {
    const state = reactive({
      zip_code: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: ''
    })

    const rules = {
      zip_code: {
        required: helpers.withMessage('Informe seu CEP', required)
      },
      address: {
        required: helpers.withMessage('Informe seu endereço', required)
      },
      number: {
        required: helpers.withMessage('Informe o número', required)
      },
      complement: {},
      neighborhood: {
        required: helpers.withMessage('Informe o bairro', required)
      },
      city: {
        required: helpers.withMessage('Informe a cidade', required)
      },
      state: {
        required: helpers.withMessage('Informe o estado', required)
      }
    }

    const v$ = useVuelidate(rules, state)
    return { v$ }
  }
}
</script>

<style scoped>
.select-input {
  appearance: none;
  font-size: 16px;
}
</style>