<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white">
      <NavBar :showMenu="false" />
    </div>
    <div class="padding-page d-flex justify-content-start background-silver py-5 w-100 min-height-page">
      <SideBar />
      <div class="d-flex flex-column fs-30 w-100 align-items-start">
        <h2 class="fw-bold mt-4 text-center">{{ locale.account.plan.title }}</h2>

        <div class="row w-100">
          <div class="col-sm-12 col-lg-10 row bg-white card-rounded ms-0 mt-3">
            <div class="col-sm-8 col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <div class="col-sm-12 d-flex align-items-center">
                <span class="badge-subscriber fs-medium" :style="styleBadge">
                  {{ subscription.badge_text }}
                </span>
              </div>
              <div class="col-sm-12 d-flex align-items-center" v-if="isFreePlan">
                <span class="badge-subscriber fs-medium">
                  <a href="/plans" class="fs-medium link-color fw-bold">
                    {{ locale.account.plan.subscribe_now }}
                  </a>
                </span>
              </div>
            </div>
            <div class="col-sm-4 col-lg-6 d-flex flex-column align-items-start justify-content-start p-3">
              <div class="d-flex flex-column">
                <span class="fw-bold fs-20">
                  {{ plan?.name }}
                </span>
                <span class="fs-20">
                  {{ this.subscription?.screens }} {{ this.subscription?.screens > 1 ? locale.plans.screens.toLowerCase() : locale.plans.screen.toLowerCase() }}
                </span>
              </div>
              <div class="d-flex flex-column">
                <span class="fw-bold fs-20">
                  {{ locale.account.plan.period }}
                </span>
                <span class="fs-20">
                  {{ subscription?.start_date }} {{ locale.account.plan.until }} {{ subscription?.end_date }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'
import locale from '@/config/locales'
import api from '@/services/api'
import DateFormat from '@/mixins/DateFormat'

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

const badges = {
  paid: '#701E66',
  authorized: '#EE831F',
  in_analysis: '#EE831F',
  waiting: '#EE831F',
  free: '#348C1B',
  expired: '#D40000'
}

export default {
  name: 'PlanView',
  components: {
    SideBar,
    NavBar
  },
  data() {
    return {
      subscription: {},
      payment: {},
      plan: {}
    }
  },
  created() {
    if (!this.currentUser?.id) {
      this.$router.push('/sign-in')
    }

    this.fetchPlan()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    locale() {
      return locale
    },
    styleBadge() {
      return {
        "background-color": badges[this.subscription?.payment_status]
      }
    },
    isFreePlan() {
      return ['free', 'expired'].includes(this.subscription?.payment_status)
    }
  },
  methods: {
    async fetchPlan() {
      try {
        const response = await api.subscriptions.byUser(this.currentUser.id)

        this.plan = response.data.plan || response.data.last_plan
        const payment_status = response.data?.payment_status
        const screens = response.data?.subscription?.screens || response.data?.last_subscription?.screens
        const start_date = response.data?.subscription?.start_date || response.data?.last_subscription?.start_date
        const end_date = response.data?.subscription?.end_date || response.data?.last_subscription?.end_date

        this.subscription = {
          screens,
          payment_status,
          start_date: this.formatDate(start_date),
          end_date: this.formatDate(end_date),
          badge_text: locale.account.plan[payment_status].badge
        }

        if (response.data.first) {
          const dateSince = new Date(response.data.first.created_at)
          const monthSince = dateSince.getUTCMonth()
          const badgeText = `${MONTHS[monthSince]} de ${dateSince.getUTCFullYear()}`

          this.subscription.badge_text = locale.account.plan[this.subscription.payment_status].badge.replace('{0}', badgeText)
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  mixins: [
    DateFormat
  ]
}
</script>

<style scoped>
.badge-subscriber {
  color: #FFFFFF;
  padding: 10px;
  border-radius: 0px 25px 25px 0px;
  text-align: center;
  width: 100%;
  margin-left: -25px;
}
</style>