<template>
  <div class="background-primary align-content-center full-screen">
    <div class="full-screen d-flex flex-column align-items-center">
      <div class="d-flex flex-column align-items-center justify-content-center logo-bg">
      </div>
        <div class="logo mt-5 mb-5 d-flex justify-content-center content-width">
          <img src="@/assets/images/logo-horizontal-white.png" alt="MeuStory.TV" />
        </div>
        <div class="d-flex flex-column border-login full-screen content-width">
          <button type="button" v-if="!showFormLogin" class="primary-button mt-4" @click="showLogin">
            <span class="fw-bold fs-15">
              {{ localeLogin.access }}
            </span>
          </button>
          <div v-if="showFormLogin" class="d-flex flex-column">
            <FormSignIn @goto-forgot-password="goToForgotPassword" @goto-sign-up="goToSignUp" redirect="/accounts" />
          </div>
          <button type="button" class="primary-button-outlined mt-3 mb-5" @click="goToSignUp" v-if="!showFormLogin">
            <span class="fw-bold fs-15">
              {{ localeLogin.create_your_account }}
            </span>
          </button>

          <figure class="qrcode d-flex flex-column" v-if="authorization_code">
            <span class="fw-bold fs-12 align-self-center fc-label">
              {{ localeLogin.qrcode }}
            </span>
            <vue-qrcode
              :value="externalLink"
              tag="svg"
              :options="{
                color: {
                  dark: '#902584'
                }
              }"
            ></vue-qrcode>
            <img class="qrcode__image" src="@/assets/images/logo-qrcode.png" alt="QR Code" />
          </figure>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import { getCookie, setCookie, clearAllIntervals } from '@/utils/cookies'
import FormSignIn from '@/components/auth/FormSignIn'
import locale from '@/config/locales'

export default {
  name: 'SignInView',
  data() {
    return {
      submitted: false,
      error_message: '',
      showFormLogin: false,
      watchIntervalAuthorize: null,
      authorization_code: ''
    }
  },
  components: {
    FormSignIn
  },
  created() {
    if (this.currentUser) {
      this.$router.push({
        path: '/accounts',
        query: {
          authorization_code: this.$route.query.authorization_code
        }
      })
    }
    this.showFormLogin = this.$route.query.show_form || false
    clearAllIntervals()
    this.createAuthorization()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    externalLink() {
      return `${process.env.VUE_APP_BASE_URL}/sign-in?authorization_code=${this.authorization_code}&redirect=${this.$route.query.redirect || '/accounts'}`
    },
    localeLogin() {
      return locale.login
    }
  },
  methods: {
    showLogin() {
      this.showFormLogin = true
    },
    createAuthorization() {
      const authorization_code = this.$route.query.authorization_code || getCookie('authorization_code')

      if (authorization_code) {
        this.authorization_code = authorization_code
        setCookie('authorization_code', authorization_code, 7)
        this.watchAuthorization(authorization_code)
        return
      }

      api.auth.createAuthorization().then(response => {
        const { authorization_code } = response.data
        this.authorization_code = authorization_code
        setCookie('authorization_code', authorization_code, 7)
        this.watchAuthorization(authorization_code)
      }).catch(error => {
        console.error('Erro ao criar request login:', error)
      })
    },
    watchAuthorization(authorization_code) {
      setInterval(() => {
        api.auth.verifyAuthorization(authorization_code).then(response => {
          if (response.data.token && response.data.vuex) {
            localStorage.setItem('jwt-token', response.data.token)
            localStorage.setItem('vuex', response.data.vuex)
            clearAllIntervals()
            window.location.reload()
          }
        }).catch(error => {
          console.error('Erro ao verificar request login:', error)
        })
      }, 3000)
    },
    goToSignUp() {
      this.$router.push({
        path: '/sign-up',
        query: {
          authorization_code: getCookie('authorization_code'),
          redirect: this.$route.query.redirect
        }
      })
    },
    goToForgotPassword() {
      this.$router.push({
        path: '/forgot-password',
        query: {
          authorization_code: getCookie('authorization_code')
        }
      })
    }
  }
}
</script>

<style scoped>
.logo {
  position: relative;
}

.qrcode {
  align-self: center;
  width: 200px;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}
</style>