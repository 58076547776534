<template>
  <div class="d-flex flex-column justify-content-center align-items-center w-100 box-title">
    <span class="fs-20 fw-bold color-white">
      {{ localePlans.annual_plan }}
    </span>
    <span class="fs-16 fw-light color-white">
      {{ selectedPlan.screens }} {{ selectedPlan.screens > 1 ? localePlans.screens : localePlans.screen }} | 
      {{ selectedPlan.billing_cycle }}x {{ currency(selectedPlan.total / selectedPlan.billing_cycle) }}
    </span>
  </div>
  <h4 class="mt-4 mb-4" v-html="localePlans.create_or_access_account"></h4>  
  <div class="d-flex flex-column align-items-center w-100 position-relative border-top pt-4 min-height-auth">
    <button type="button"
            class="width-btn-login primary-button-outlined d-flex justify-content-center align-items-center"
            @click="view = 2"
            v-if="view === 0">
      <span class="fw-bold fs-15">
        {{ localeLogin.create_new_account }}
      </span>
    </button>
  
    <button type="button"
            class="margin-bottom-200 width-btn-login primary-button mt-4 d-flex justify-content-center align-items-center"
            @click="view = 1"
            v-if="view === 0">
      <span class="fw-bold fs-15">
        {{ localeLogin.access_account }}
      </span>
    </button>
  
    <div v-if="isSignIn" class="w-85">
      <FormSignIn
        @goto-sign-up="changeView('sign-up')"
        @goto-forgot-password="changeView('forgot-password')"
        :redirect="redirect"
      />
    </div>
  
    <div v-if="isSignUp" class="w-85">
      <FormSignUp
        @goto-sign-in="changeView('sign-in')"
        :redirect="redirect"
      />
    </div>

    <div v-if="isForgotPassword" class="w-85">
      <FormForgotPassword @goto-sign-in="changeView('sign-in')" />
    </div>
  </div>
</template>

<script>
import locale from '@/config/locales'
import FormSignIn from './FormSignIn'
import FormSignUp from './FormSignUp'
import FormForgotPassword from './FormForgotPassword'
import Currency from '@/mixins/Currency'

export default {
  name: 'Auth',
  data() {
    return {
      view: 'sign-in'
    };
  },
  props: {
    selectedPlan: {
      type: Object,
      default: () => ({})
    },
    redirect: {
      type: String,
      default: '/accounts'
    }
  },
  components: {
    FormSignIn,
    FormSignUp,
    FormForgotPassword
  },
  computed: {
    localeLogin() {
      return locale.login
    },
    localePlans() {
      return locale.plans
    },
    isSignIn() {
      return this.view === 'sign-in'
    },
    isSignUp() {
      return this.view === 'sign-up'
    },
    isForgotPassword() {
      return this.view === 'forgot-password'
    }
  },
  methods: {
    changeView(view) {
      this.view = view
    }
  },
  mixins: [
    Currency
  ]
}
</script>

<style scoped>
.width-btn-login {
  width: 300px;
}

.margin-bottom-200 {
  margin-bottom: 200px;
}

.box-title {
  background-color: #22A445;
  border-radius: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 24px;
}
</style>
