
<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white z-3s">
      <NavBar :showMenu="false" />
    </div>
    <div class="padding-page d-flex justify-content-start background-silver py-5 w-100 min-height-page">
      <SideBar />
      <div class="d-flex flex-column fs-30 w-100 align-items-start">
        <div class="d-flex align-items-end">
          <span class="text-secondary fw-bold fs-12 text-center">{{ locale.control_panel.title }} > </span>
          <span class="ms-1 fw-bold fs-20 text-center lh-sm">{{ locale.control_panel.sidebar.accounts }}</span>
        </div>

        <div class="w-100 text-center">
          <div class="input-with-icon">
            <input type="text"
                   class="col-12 bg-white card-rounded p-3 border-0 elevation-1 mt-3 w-100 ps-4"
                   :placeholder="locale.control_panel.search"
                   v-model="search"
                   @input="updateSearch"
            />
            <img src="@/assets/icons/magnifying-glass.png" alt="Pesquisar" class="icon-md" />
          </div>
          <div class="col-12 bg-white card-rounded mt-3 pt-1 d-flex align-items-center text-start" v-if="!loading || accounts.length > 0">
            <table class="table fs-16 mb-0" v-if="accounts.length > 0">
              <thead>
                <tr>
                  <th width="5%"></th>
                  <th>{{ locale.control_panel.table.username }}</th>
                  <th>{{ locale.control_panel.table.instagram }}</th>
                  <th>{{ locale.control_panel.table.expired_at }}</th>
                  <th width="1%"></th>
                  <th width="1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="account in accounts" :key="account.id">
                  <td :align="'center'" class="align-middle">
                    <img :src="account.picture" width="34" class="rounded-circle ps-2" />
                  </td>
                  <td class="align-middle">
                    <span class="fs-medium text-start">
                      {{ account.username }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span class="fs-medium text-start">
                      @{{ account.name }}
                    </span>
                  </td>
                  <td class="align-middle">
                      <span class="fs-medium text-start">
                      {{ account.expired_at }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <!-- <router-link :to="{ name: 'admin-accounts', params: { id: account.id } }">
                      <button class="btn btn-icon">
                        <img src="@/assets/icons/user-edit.png" alt="Pesquisar" class="icon-sm" />
                      </button>
                    </router-link> -->
                  </td>
                  <td>
                    <router-link :to="{ name: 'dashboard-account', params: { account_id: account.id } }">
                      <button class="btn btn-icon">
                        <img src="@/assets/icons/user-graph.png" alt="Gráficos" class="icon-sm" />
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 pt-3 pb-3 d-flex align-items-center justify-content-center" v-if="!loading && accounts.length === 0">
              <span class="fs-16 fw-bold text-secondary">
                {{ locale.control_panel.table.noResults }}
              </span>
            </div>
          </div>
          <div class="col-12 mt-3 pt-3 d-flex align-items-center justify-content-center" v-if="loading && accounts.length === 0">
            <div class="spinner-border text-secondary" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>
          <button class="btn-show-more mt-3" @click="loadMoreAccounts" v-if="hasMoreAccount">
            <span class="fs-16 fw-bold align-middle" v-if="!loading">
              {{ locale.control_panel.table.loadMore }}
            </span>
            <div class="spinner-border spinner-border-sm text-secondary align-middle" role="status" v-if="loading && accounts.length > 0">
              <span class="visually-hidden"></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import SideBar from './components/SideBar'
import NavBar from '@/components/NavBar'
import locale from '@/config/locales'
import DateFormat from '@/mixins/DateFormat'

export default {
  name: 'AdminAccountsView',
  components: {
    SideBar,
    NavBar
  },
  data: () => ({
    accounts: [],
    total_accounts: 0,
    search: '',
    page: 1,
    searching: null,
    loading: false
  }),
  created() {
    if (!this.currentUser) {
      this.$router.push('/sign-in')
    } else if (this.currentUser && this.isCustomer) {
      this.$router.push('/accounts')
    }

    this.searchAccounts()
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    isCustomer() {
      return !this.currentUser.permission || this.currentUser.permission < 1
    },
    locale() {
      return locale
    },
    hasMoreAccount() {
      return this.accounts.length < this.total_accounts
    }
  },
  methods: {
    async searchAccounts() {
      this.loading = true
      if (this.searching) {
        clearTimeout(this.searching)
      }

      this.searching = setTimeout(async () => {
        this.searching = null
        const { data } = await api.accounts.search({
          search: this.search,
          page: this.page
        })

        this.accounts = this.accounts.concat(data.accounts.map(account => ({
          ...account,
          expired_at: this.formatDate(account.expired_at)
        })))

        this.total_accounts = data.total

        this.loading = false
      }, 500)
    },
    loadMoreAccounts() {
      this.page++
      this.searchAccounts()
    },
    updateSearch() {
      this.page = 1
      this.accounts = []
      this.searchAccounts()
    }
  },
  mixins: [
    DateFormat
  ]
}
</script>

<style scoped>
.btn-show-more {
  background-color: #FFF;
  border: none;
  padding: 0px 30px;
  border-radius: 20px;
}

table > tbody > tr:last-child {
  border-style: hidden;
  border-top: 1px solid #dee2e6;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  padding-left: 40px;
}

.input-with-icon img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-25%);
}

.input-with-icon .bg-white {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>