const locale = {
  breadcrumbs: {
    home: 'Página inicial',
    prices: 'Preços',
    talk_whatsapp: 'Conversar WhatsApp',
    about: 'O que é o MeuStory.TV',
    my_account: 'Acessar MeuStory.tv',
    login: 'Entrar',
  },
  no_medias: {
    message: 'Nenhum story/reels encontrados nesta conta'
  },
  error_plan_expired: {
    buttonConfirm: 'Ver planos',
    message: 'Seu plano expirou, contrate um plano ou entre em contato com o nosso time',
    confirm: () => {
      window.location.href = '/plans'
    }
  },
  limit_exceeded: {
    buttonConfirm: 'Ver planos',
    message: 'Limite de telas excedido, contrate mais telas ou entre em contato com o nosso time',
    confirm: () => {
      window.location.href = '/plans'
    }
  },
  err_network: {
    message: 'Houve um erro de conexão ao buscar os stories, recarregue a página e tente novamente',
    buttonConfirm: 'Recarregar página',
    confirm: () => {
      window.location.reload()
    }
  },
  default: {
    confirm: () => {}
  },
  error_fetch_medias: 'Erro ao buscar stories/reels, por favor, tente novamente',
  understood: 'Entendi',
  credit_card: {
    title: 'Informe os dados do seu cartão de crédito',
    card_number: 'Número do cartão',
    card_holder: 'Nome impresso no cartão',
    expiration_date: 'Data de validade',
    cvv: 'CVV',
    confirm: 'Confirmar pagamento',
    back: 'Voltar',
    installments: 'Parcelas',
    resume_plan: 'Resumo do seu plano',
    change_plan: 'TROCAR',
    secure_message: 'Seus dados são criptografados de ponta a ponta, garantindo a segurança de suas informações. Antes dos dados serem enviados eles passam por uma criptografia do PagSeguro que garante que somente nosso servidor possa descriptografar e processar os dados. Nenhum dado de pagamento é armazenado em nossos servidores.',
    terms_of_service: 'Li e concordo com os <a href="/terms-of-service" target="_blank" class="fw-bold">termos de serviço</a> e a <a href="/privacy-policy" target="_blank" class="fw-bold">política de privacidade</a>',
    contract: 'Aceito o <a href="/contract" target="_blank" class="fw-bold">contrato de prestação de serviços</a> do MeuStory.TV',
  },
  finish: {
    authorized: {
      title: 'Seu pagamento foi autorizado e estamos aguardando finalizar o processamento dessa transação.',
      subtitle: 'Este processo pode levar até 24 horas',
      button: 'Acompanhar pagamento',
      redirect: '/account/plan',
      image: 'success'
    },
    paid: {
      title: 'Seu pagamento foi aprovado, bem-vindo ao MeuStory.tv',
      subtitle: 'Que você tenha uma experiência incrível',
      your_plan: 'Seu plano',
      vigency: 'Vigência de {date_start} a {date_end}',
      your_login: 'Seu login é',
      button: 'Ver minha conta',
      redirect: '/accounts',
      image: 'success'
    },
    in_analysis: {
      title: 'Seu pagamento está em análise',
      subtitle: 'Estamos aguardando animados para dar início a sua experiência',
      button: 'Acompanhar pagamento',
      redirect: '/account/plan',
      image: 'in_analysis'
    },
    declined: {
      title: 'Seu pagamento foi negado',
      subtitle: 'Por favor, confira os dados e tente novamente ou entre em contato com o suporte',
      button: 'Voltar para o pagamento',
      redirect: '/checkout/payment',
      image: 'failure'
    },
    canceled: {
      title: 'Seu pagamento foi cancelado',
      subtitle: 'O processo não foi finalizado, esperamos que esteja tudo bem',
      button: 'Voltar para o pagamento',
      redirect: '/plans',
      image: 'failure'
    },
    waiting: {
      title: 'Estamos aguardando a confirmação do pagamento',
      subtitle: 'Quase podemos te chamar de cliente ;D',
      button: 'Ver minha conta',
      redirect: '/account/plan',
      image: 'success'
    }
  },
  plans: {
    title: 'Monte o melhor plano para você',
    table: {
      concurrent_screens: 'Telas simultâneas',
      included_firetv: 'Fire TV inclusos',
      daily_investment: 'Investimento médio diário',
      accounts_logged: 'Contas logadas',
      ilimited: 'Ilimitado'
    },
    annual_plan: 'Plano Anual',
    your_annual_plan: 'Seu plano anual',
    continue_your_buy: 'Prosseguir <br />para compra',
    create_or_access_account: 'Crie ou acesse a sua <br />conta para prosseguir',
    access_existing_account: 'Acessar uma conta existente',
    screen: 'Tela',
    screens: 'Telas',
    address: {
      title: 'Informe seu endereço e receba seu(s) Fire TV configurado(s)',
      zip_code: 'CEP',
      street: 'Rua/Av.',
      number: 'Número',
      complement: 'Complemento',
      neighborhood: 'Bairro',
      city: 'Cidade',
      state: 'Estado',
      save: 'Cadastrar e prosseguir para o pagamento'
    }
  },
  login: {
    qrcode: 'Ou acesse pelo QR Code',
    access: 'Acesse sua conta',
    access_account: 'Acessar uma conta existente',
    already_have_account: 'Já tem uma conta?',
    create_account: 'Criar conta',
    create_new_account: 'Criar uma nova conta',
    create_your_account: 'Crie sua conta',
    dont_have_account: 'Não tem uma conta?',
    click_here: 'Clique aqui',
    forgot_password: 'Esqueci minha senha',
    enter: 'Entrar',
    email: 'E-mail',
    password: 'Senha',
    password_confirmation: 'Confirmar a senha',
    name: 'Nome',
    cpf_cnpj: 'CPF/CNPJ'
  },
  shipping_address: {
    title: 'Informe seu endereço e receba seu Fire TV configurado',
    zip_code: 'CEP',
    address: 'Rua/Av.',
    number: 'Número',
    complement: 'Complemento',
    neighborhood: 'Bairro',
    city: 'Cidade',
    state: 'UF',
    continue: 'Cadastrar e prosseguir para o pagamento',
    back: 'Voltar'
  },
  forgot_password: {
    title: 'Recuperar senha',
    button: 'Recuperar senha',
    success: 'Um link para a recuperação de senha <br />foi enviado para o seu e-mail.',
    back_to_login: 'Voltar para o login'
  },
  user: {
    email: 'E-mail'
  },
  home: {
    title: 'Assista e entenda como implementar na sua loja',
    continue: 'Escolha seu plano e comece a usar',
  },
  account: {
    sidebar: {
      title_back: 'Voltar para o MeuStory.tv',
      account: 'Minha conta',
      plan: 'Meu plano',
      tutorials: 'Tutoriais',
      logout: 'Sair da conta',
      back: 'Voltar para o MeuStory.tv',
      rotate: 'Rotacionar tela',
      control_panel: 'Painel de controle',
    },
    user: {
      title: 'Minha conta',
      change_password: 'Alterar senha',
      edit: 'Atualizar meus dados',
    },
    plan: {
      title: 'Meu plano',
      subscriber_since: 'Assinante desde',
      period: 'Período',
      until: 'até',
      subscribe_now: 'Contrate agora',
      paid: {
        badge: 'Assinante desde {0}'
      },
      authorized: {
        badge: 'Pagamento em análise'
      },
      in_analysis: {
        badge: 'Pagamento em análise'
      },
      waiting: {
        badge: 'Aguardando pagamento'
      },
      free: {
        badge: 'Plano de teste'
      },
      expired: {
        badge: 'Plano expirado'
      }
    },
    tutorials: {
      title: 'Tutoriais',
      search: 'Pesquise tutoriais aqui',
      noResults: 'Nenhum tutorial encontrado',
      items: [
        {
          title: 'Vídeo principal: Estrutura da sua conta',
          description: 'Entenda como a conexão de contas do MeuStory.tv com Facebook e Instagram funciona de forma simples.',
          image: 'tutorial_1',
          link: 'https://www.youtube.com',
          tags: ['Conexão de contas'],
          favorite: true
        },
        {
          title: '1. Como conectar meu Instagram com o MeuStory.tv',
          description: 'Após criar sua conta no MeuStory.tv, te ensinamos como conectar seu Instagram no sistema pelo.',
          image: 'tutorial_2',
          link: 'https://www.youtube.com',
          tags: ['Conexão de contas']
        },
        {
          title: '2. Como vincular Instagram ao seu Facebook',
          description: 'Caso seu Instagram não esteja conectado ao seu Facebook, aqui te ensinamos como fazer esse proecesso.',
          image: 'tutorial_3',
          link: 'https://www.youtube.com',
          tags: ['Conexão de contas']
        },
        {
          title: '3. Como criar uma página no Facebook',
          description: 'Se você não tiver uma página no Facebook, será essencial criar uma e nesse vídeo te ensinamos como.',
          image: 'tutorial_4',
          link: 'https://www.youtube.com',
          tags: ['Conexão de contas']
        },
        {
          title: '4. Ajustes de visualização',
          description: 'Aprenda sobre todos os filtros e formatos de exibição dos víveos em sua tela.',
          image: 'tutorial_5',
          link: 'https://www.youtube.com',
          tags: ['Ajustes de visualização']
        },
        {
          title: '5. Otimizando seu uso',
          description: 'Iremos de dar conselhos de como otimizar a exibição do MeuStory.tv como rede de internet e configurações da TV.',
          image: 'tutorial_6',
          link: 'https://www.youtube.com',
          tags: ['Ajustes de visualização']
        },
        {
          title: '6. Conselhos de onde posicionar sua TV',
          description: 'Com base nos clientes de maior sucesso, te aconselhamos sobre o posicionamento da sua TV.',
          image: 'tutorial_7',
          link: 'https://www.youtube.com',
          tags: ['Ajustes de visualização']
        }
      ]
    }
  },
  control_panel: {
    title: 'Painel de controle',
    search: 'Pesquise aqui',
    table: {
      noResults: 'Nenhum resultado encontrado',
      username: 'Nome de usuário',
      instagram: 'Instagram',
      expired_at: 'Expira em',
      userEdit: 'Editar usuário',
      userDashboard: 'Gráficos',
      loadMore: 'Carregar mais',
    },
    sidebar: {
      accounts: 'Contas',
    },
    accounts: {
      monitoring: 'Monitoramento de uso'
    }
  },
}

export default locale