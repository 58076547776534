
<template>
  <div class="d-flex flex-column w-100 align-self-start">
    <div class="padding-page d-flex flex-column align-self-start w-100 bg-white z-3s">
      <NavBar :showMenu="false" />
    </div>
    <div class="padding-page d-flex justify-content-start background-silver py-5 w-100 min-height-page">
      <SideBar />
      <div class="d-flex flex-column fs-30 w-100 align-items-start">
        <h2 class="fw-bold mt-4 text-center">{{ locale.account.tutorials.title }}</h2>

        <div class="row w-100">
          <input type="text"
                 class="col-10 bg-white card-rounded p-3 border-0 elevation-1 mt-3"
                 :placeholder="locale.account.tutorials.search"
                 v-model="search"
                 @input="searchTutorials"
          />
          <button class="col-10 row bg-white card-rounded mt-3 py-3 d-flex align-items-center"
                  v-for="item in tutorials" :key="item.title"
                  @click="() => goToTutorial(item.link)"
                  :class="tutorialItemClass(item)">
            <img src="../../assets/images/tutorial.png" alt="tutorial" class="col-4 thumb-video" />
            <div class="col-8 d-flex flex-column">
              <span class="fw-bold fs-medium text-start">
                {{ item.title }}
              </span>
              <span class="fs-medium text-start">
                {{ item.description }}
              </span>
            </div>
          </button>
          <div class="col-10 bg-white card-rounded mt-3 py-3 d-flex align-items-center justify-content-center" v-if="tutorials.length === 0">
            <span class="fs-medium">
              {{ locale.account.tutorials.noResults }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar'
import NavBar from '@/components/NavBar'
import locale from '@/config/locales'

export default {
  name: 'TutorialsView',
  components: {
    SideBar,
    NavBar
  },
  data: () => ({
    tutorials: [],
    search: ''
  }),
  created() {
    this.tutorials = this.locale.account.tutorials.items
  },
  computed: {
    locale() {
      return locale
    }
  },
  methods: {
    goToTutorial(link) {
      window.open(link, '_blank')
    },
    tutorialItemClass(item) {
      if (item.favorite) {
        return 'border-primary border-5'
      }

      return 'border-0'
    },
    searchTutorials() {
      this.tutorials = this.locale.account.tutorials.items.filter(item => {
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  }
}
</script>

<style scoped>
.thumb-video {
  width: 220px;
  height: auto;
  border-radius: 40px
}
</style>