export default (api) => ({
  get: () => api.get('/accounts'),
  getById: (id) => api.get(`/accounts/${id}`),
  create: (params) => api.post('/accounts', params),
  update: (params) => api.put(`/accounts/${params.id}`, params),
  delete: (id) => api.delete(`/accounts/${id}`),
  stories: (id, session_id) => api.get(`/accounts/${id}/stories?session_id=${session_id}`, { timeout: 1000 * 60 * 10 }),
  reels: (id, session_id) => api.get(`/accounts/${id}/reels?session_id=${session_id}`, { timeout: 1000 * 60 * 10 }),
  callback: (params) => api.post(`/accounts/callback`, params),
  by_name: (params) => api.get(`/accounts/by_name`, { params }),
  update_admin: (params) => api.put(`/accounts/${params.id}/update_admin`, params),
  search: (params) => api.get(`/accounts/search`, { params }),
  getByIdAdmin: (id) => api.get(`/accounts/${id}/by_id`),
})