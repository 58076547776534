<template>
  <span class="title-auth">{{ localeLogin.create_your_account }}</span>
  <form @submit.prevent="submitForm" class="p-4 d-flex flex-column" autocomplete="off">
    <input :placeholder="localeLogin.name" type="text" v-model="v$.name.$model" class="input-login" />
    <span v-if="v$.name.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.name.$errors[0]?.$message }}</span>
    <input :placeholder="localeLogin.cpf_cnpj" type="text" v-model="v$.document.$model" class="input-login mt-3" v-mask="documentMask" />
    <span v-if="v$.document.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.document.$errors[0]?.$message }}</span>
    <input :placeholder="localeLogin.email" type="email" v-model="v$.userEmail.$model" class="input-login mt-3" />
    <span v-if="v$.userEmail.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.userEmail.$errors[0]?.$message }}</span>
    <input :placeholder="localeLogin.password" type="password" v-model="v$.password.$model" class="input-login mt-3" />
    <span v-if="v$.password.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.password.$errors[0]?.$message }}</span>
    <input :placeholder="localeLogin.password_confirmation" type="password" v-model="v$.confirmPassword.$model" class="input-login mt-3" />
    <span v-if="v$.confirmPassword.$errors.length && submitted" class="color-danger fs-12 mt-3">{{ v$.confirmPassword.$errors[0]?.$message }}</span>
    <span v-if="error_message" class="color-danger fs-12 mt-3">{{ error_message }}</span>
    <button type="submit" class="primary-button mt-4 d-flex justify-content-center align-items-center">
      <span class="fw-bold fs-15 align-self-center" v-if="!loading">
        {{ localeLogin.create_account }}
      </span>
      <div class="spinner-border spinner-border-sm text-light" role="status" v-else>
        <span class="visually-hidden"></span>
      </div>
    </button>
    <div class="d-flex justify-content-center align-items-center mt-3 fw-bold fc-label">
      <span class="fs-12">{{ localeLogin.already_have_account }}</span><button @click="$emit('goto-sign-in')" type="button" class="btn btn-link fs-12 p-1 fw-bold">Clique aqui</button>
    </div>
  </form>
</template>

<script>
import { required, email, helpers, sameAs, minLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { ref } from 'vue'
import api from '@/services/api'
import { getCookie, clearAllIntervals } from '@/utils/cookies'
import locale from '@/config/locales'

export default {
  name: 'FormSignUp',
  data() {
    return {
      submitted: false,
      error_message: '',
      loading: false
    }
  },
  props: {
    redirect: {
      type: String,
      default: '/accounts'
    }
  },
  computed: {
    localeLogin() {
      return locale.login
    },
    documentMask() {
      return this.v$.document.$model.length > 14 ? '##.###.###/####-##' : '###.###.###-##'
    }
  },
  methods: {
    ...mapActions('auth', ['setCurrentUser']),
    async submitForm() {
      if (this.loading) return

      this.loading = true

      try {
        const authorization_code = getCookie('authorization_code')
        this.error_message = ''
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          this.submitted = true
          return
        }

        const params = {
          name: this.v$.name.$model,
          document: this.v$.document.$model,
          email: this.v$.userEmail.$model,
          password: this.v$.password.$model,
          authorization_code
        }

        const response = await api.auth.signUp(params)

        const { data } = response

        this.setCurrentUser(data)
        clearAllIntervals()
        api.auth.setVuex({ authorization_code, vuex: localStorage.getItem('vuex') })

        // eslint-disable-next-line
        $('#loginModal')?.modal('hide')

        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
          return
        }

        this.$router.push(this.redirect)
      } catch (error) {
        console.error('Erro ao criar conta:', error)
        this.error_message = error.response.data.error[0]
      } finally {
        this.loading = false
      }
    },

  },
  setup () {
    const name = ref('')
    const document = ref('')
    const userEmail = ref('')
    const password = ref('')
    const confirmPassword = ref('')

    const rules = {
      name: {
        required: helpers.withMessage('Informe seu nome', required)
      },
      document: {
        required: helpers.withMessage('Informe seu CPF ou CNPJ', required)
      },
      userEmail: {
        required: helpers.withMessage('Informe seu e-mail', required),
        email: helpers.withMessage('Informe um e-mail válido', email)
      },
      password: {
        required: helpers.withMessage('Informe sua senha', required),
        minLength: helpers.withMessage('Mínimo: 6 caracteres', minLength(6))
      },
      confirmPassword: {
        required: helpers.withMessage('Confirme sua senha', required),
        sameAsPassword: helpers.withMessage('As senhas precisam ser iguais', sameAs(password))
      }
    }

    const v$ = useVuelidate(rules, {
      name,
      document,
      userEmail,
      password,
      confirmPassword
    })

    return { v$ }
  }
}
</script>