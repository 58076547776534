<template>
  <div style="display: flex; flex-direction: column; align-items: flex-start; padding-left: 30px; height: 100%; max-width: 90%;">
    <h2 class="fw-bold mt-4 align-self-center">Contratação do acesso ao software – MeuStory.tv</h2>
    <h3 class="fw-bold mt-4">1. IDENTIFICAÇÃO DAS PARTES</h3>
    <p>1.1. CONTRATANTE: {{ currentUser.name }} inscrito no CPF/CNPJ sob o n.º {{ currentUser.document }}.</p>
    <p>1.2. CONTRATADA: CURADORIA DIGITAL, inscrita no CNPJ sob o n.º 39.767.564/0001-90, com sede na Rua Alfredo Feiten, 888 Centro - Parobé/RS, CEP: 95630-000, pelo sócio MARCELO SANGIOGO.</p>
    <h3 class="fw-bold mt-4">2. OBJETO DO CONTRATO</h3>
    <p>2.1. Fornecimento de SOFTWARE na modalidade SaaS (Software as a Service) durante 12 meses.</p>
    <h3 class="fw-bold mt-4">3. CARACTERÍSTICAS DO SOFTWARE</h3>
    <p>3.1. Plataforma web acessível e funcional somente a partir do aparelho Fire TV (bônus do plano anual) e de computadores.</p>
    <p>3.2. Infraestrutura em nuvem sob responsabilidade da CONTRATADA.</p>
    <p>3.3. Equipamentos, TVs, monitores, internet rápida e estável  sob responsabilidade do CONTRATANTE.</p>
    <h3 class="fw-bold mt-4">4. RESPONSABILIDADES</h3>
    <p>4.1. Da CONTRATADA: • Manutenção e atualizações do sistema para sua funcionalidade • Suporte técnico em dias úteis (9h-12h e 13:30-17:30) • Correção de problemas de software • Proteção de dados dos usuários.</p>
    <p>4.2. Do CONTRATANTE: • Uso adequado do sistema • Gerenciamento de informações • Capacitação dos usuários • Comunicação de problemas para serem solucionados.</p>
    <h3 class="fw-bold mt-4">5. CONDIÇÕES COMERCIAIS</h3>
    <p>5.1. Pagamento efetuado no momento da aquisição do software e contabilizado a sua vigência a partir desta data.</p>
    <p>5.2. A CONTRATADA fica obrigada a prestar suporte técnico aos USUÁRIOS da CONTRATANTE em dias úteis, das 09h às 12h e das 13:30 às 17:30, horário de Brasília, por meio dos canais de atendimento previstos para o plano e versão contratada.</p>
    <h3 class="fw-bold mt-4">6. ATUALIZAÇÕES</h3>
    <p>6.1. A CONTRATADA se reserva ao direito de, a qualquer tempo, sem aviso prévio, efetuar modificações e atualizações no SOFTWARE para correção de vícios ou defeitos de sistema (bugs), adequações legais ou normativas, implantações de novas funcionalidades, otimizações e ajustes de performance. Atualizações de previsível indisponibilização do SOFTWARE deverão ser informadas no painel gerencial do SOFTWARE, com 7 (sete) dias de antecedência.</p>
    <p>6.2. Excepcionalmente, em caráter emergencial, no caso de algum evento adverso que possa estar impactando na disponibilidade ou performance do SOFTWARE, poderão ser tomadas medidas de correção que gerem indisponibilidade momentânea do mesmo.</p>
    <h3 class="fw-bold mt-4">7. COMODATO</h3>
    <p>7.1. Neste presente contrato, não há implementações de comodato, o aparelho Fire TV que você recebe ao contratar seu plano é um bônus, onde não é cobrado e não implica em garantia do mesmo para possíveis problemas de hardware.</p>
    <h3 class="fw-bold mt-4">8. PAGAMENTO E PRAZOS</h3>
    <p>8.1. A CONTRATANTE efetuará o pagamento uma única vez ao ano para acesso ao plano SaaS, objeto deste CONTRATO, à CONTRATADA. A renovação implica no pagamento do próximo ano nos preços atualizados da plataforma.</p>
    <p>8.2. A CONTRATANTE possui um número determinado de TELAs contratadas. Se o número de TELAS contratadas for insuficiente, a CONTRATANTE deverá efetuar a contratação de novos USUÁRIOS através da equipe de suporte ou adicionando pela plataforma de vendas do MeuStory.tv.</p>
    <p>8.3. Caso a CONTRATANTE necessite de uma tela adicional no ambiente, será concedida pelo investimento adicional previsto na tela de montagem do seu plano.</p>
    <h3 class="fw-bold mt-4">9. RESCISÃO E PENALIDADES</h3>
    <p>9.1. O presente instrumento poderá ser rescindido de forma imotivada por qualquer uma das partes. Sem caber pagamento de multa ou qualquer custo adicional a ambas as partes.</p>
    <h3 class="fw-bold mt-4">10. EXCLUDENTE DE RESPONSABILIDADES</h3>
    <p>10.1. O SOFTWARE é considerado um projeto fechado, com escopo definido, ou seja, não contempla solicitações adicionais pelo cliente. O SOFTWARE é fornecido "como está", sem nenhuma outra garantia.</p>
    <p>10.2. A CONTRATANTE assume todos os riscos e responsabilidades pela escolha do SOFTWARE para alcançar os resultados desejados. A CONTRATADA fica obrigada a fornecer todas as funcionalidades constantes.</p>
    <p>10.3. Não há responsabilidade por mudanças nas plataformas Instagram e Facebook que inviabilizem o uso da ferramenta e que impossibilitem a plataforma de existir.</p>
    <p>10.4. A CONTRATADA utiliza os acessos certificados pelas APIs do Facebook e qualquer possível bloqueio de contas ou ações nesse sentido não serão de responsabilidade da CONTRATADA.</p>
    <h3 class="fw-bold mt-4">11. DISPOSIÇÕES GERAIS</h3>
    <p>11.1. Este CONTRATO é vinculante entre as partes e será considerado em benefício de ambas e de seus respectivos sucessores e cessionários autorizados.</p>
    <p>11.2. A única legislação aplicável ao presente instrumento é a da República Federativa do Brasil sem limitação de tempo e lugar.</p>
    <p>11.3. A CONTRATANTE autoriza ser citada como cliente para materiais de comunicação e marketing.</p>
    <h3 class="fw-bold mt-4">ACEITE DOS TERMOS</h3>
    <p>Por ser justo e acertado entre as partes a CONTRATANTE ao contratar o SOFTWARE pelo website ou via Proposta Comercial que contenha aceite do presente CONTRATO, declara ter acessado, lido e compreendido a Política de Privacidade, os Termos de Uso e o presente CONTRATO, estando totalmente de acordo com as condições descritas.</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ContractView',
  computed: {
    ...mapState('auth', ['currentUser'])
  }
}
</script>